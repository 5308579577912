import { stringify } from './string';
import { isArray, isUndefined } from './type-guards';

export const filterPages = (
  pages: Queries.Maybe<readonly Queries.Squidex_Pages[]> | undefined,
  predicate: (
    page: Queries.Squidex_Pages,
    parent: Queries.Squidex_Pages | null,
  ) => boolean,
  parent: Queries.Squidex_Pages | null = null,
): readonly Queries.Squidex_Pages[] => {
  const filteredPages = pages?.reduce(function reducer(pages, page) {
    if (predicate(page, parent)) {
      pages.push({
        ...page,
        referencingPagesContents: page.referencingPagesContents
          ? filterPages(page.referencingPagesContents, predicate, page)
          : null,
      });
    }

    return pages;
  }, [] as Queries.Squidex_Pages[]);

  return filteredPages || [];
};

export const filterInternalPages = (
  pages: Queries.Maybe<readonly Queries.Squidex_Pages[]> | undefined,
): readonly Queries.Squidex_Pages[] =>
  filterPages(pages, (page) => {
    const path = stringify(page.flatData.path);
    return path.startsWith(`/`);
  });

export const filterActivePages = (
  pages: Queries.Maybe<readonly Queries.Squidex_Pages[]> | undefined,
  settings: Queries.Squidex_Settings | undefined,
): readonly Queries.Squidex_Pages[] =>
  filterPages(
    pages,
    (page, parent) =>
      isActiveSeason(page.flatData.season, settings) ||
      isActiveParent(page, parent),
  );

export const isActiveParent = (
  page: Queries.Squidex_Pages,
  parent: Queries.Squidex_Pages | null = null,
): boolean => {
  const seasonAlias = page.flatData.season?.[0]?.flatData.alias;
  const parentAlias = parent?.flatData.alias;

  return seasonAlias === parentAlias;
};

export const isActiveSeason = (
  seasons: Queries.Maybe<readonly Queries.Squidex_Seasons[]> | undefined,
  settings: Queries.Squidex_Settings | undefined,
): boolean => {
  if (!isArray(seasons) || seasons.length === 0) {
    return true;
  }

  const activeSeason = settings?.flatData.season?.[0];
  const foundSeason = seasons.find(
    (season) => season.flatData.alias === activeSeason?.flatData.alias,
  );

  return !isUndefined(foundSeason);
};

export const sortPages = (
  pages: Queries.Maybe<readonly Queries.Squidex_Pages[]> | undefined,
  order: 'asc' | 'desc' = `asc`,
): readonly Queries.Squidex_Pages[] => {
  const sortedPages: Queries.Squidex_Pages[] = Array.isArray(pages)
    ? [...pages].filter((page): page is Queries.Squidex_Pages => page !== null)
    : [];

  sortedPages.sort((pageA, pageB) => {
    const rankA = pageA.flatData.rank || 0;
    const rankB = pageB.flatData.rank || 0;

    if (order === `asc`) {
      return rankA - rankB;
    }

    return rankB - rankA;
  });

  return sortedPages?.map((page) => ({
    ...page,
    referencingPagesContents: page.referencingPagesContents
      ? sortPages(page.referencingPagesContents)
      : null,
  }));
};
