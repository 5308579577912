import { useState } from 'react';
import type { ComponentProps } from 'react';

import { CarouselControlled } from './carousel-controlled';

type CarouselProps = ComponentProps<typeof CarouselControlled>;

type Props = Omit<CarouselProps, 'selectedIndex' | 'onSelect'> &
  Partial<Pick<CarouselProps, 'selectedIndex' | 'onSelect'>>;

export const Carousel = ({
  selectedIndex: initialSelectedIndex = 0,
  onSelect = () => {},
  ...props
}: Props) => {
  const [selectedIndex, setSelectedIndex] =
    useState<CarouselProps['selectedIndex']>(initialSelectedIndex);

  const selectHandler: CarouselProps['onSelect'] = (index) => {
    setSelectedIndex(index);
    onSelect?.(index);
  };

  return (
    <CarouselControlled
      {...props}
      selectedIndex={selectedIndex}
      onSelect={selectHandler}
    />
  );
};

Carousel.Items = CarouselControlled.Items;
Carousel.Item = CarouselControlled.Item;

Carousel.Controls = CarouselControlled.Controls;
Carousel.Next = CarouselControlled.Next;
Carousel.Prev = CarouselControlled.Prev;
Carousel.Tabs = CarouselControlled.Tabs;
Carousel.Tab = CarouselControlled.Tab;
