import { useAccessibility } from '../../hooks';

import styles from './copyright.module.scss';

export const Copyright = () => {
  const accessibility = useAccessibility();
  const currentYear = new Date().getUTCFullYear();

  return (
    <small className={styles.wrapper}>
      <span>{currentYear}</span>
      <span>{accessibility?.flatData.companyName}</span>
    </small>
  );
};
