import { graphql, useStaticQuery } from 'gatsby';

export const useWebcams = (): readonly Queries.Squidex_Webcams[] => {
  const data = useStaticQuery<Queries.Query>(query);

  return data.squidex.queryWebcamsContents || [];
};

const query = graphql`
  query useWebcamsQuery {
    squidex {
      queryWebcamsContents(orderby: "data/rank/iv asc") {
        id
        data {
          name {
            de
            en
          }
          alias {
            de
            en
          }
          panomaxIsoCode {
            de
            en
          }
          webcamPath {
            de
            en
          }
        }
        flatData {
          webcamUrl
          thumbnailUrl
          panomaxInstanceId
        }
      }
    }
  }
`;
