import { forwardRef } from 'react';
import type { HTMLAttributes } from 'react';

import { RovingIndexContainer } from '../../components';
import { useClassName, useFocusFirst, useForwardedRef } from '../../hooks';

import { NavItem } from './nav-item';
import styles from './nav-items.module.scss';
import { NavSubmenu } from './nav-submenu';


export type Props = HTMLAttributes<HTMLUListElement> & {
  pages: readonly Queries.Squidex_Pages[];
  isEnabled: boolean;
  level?: number;
};

export const NavItems = forwardRef<HTMLUListElement, Props>(function NavItems(
  { pages, isEnabled, level = 1, className, ...navItems }: Props,
  forwardedRef,
) {
  const parentRef = useForwardedRef(forwardedRef);
  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[`level${level}`],
    className,
  ]);

  useFocusFirst(parentRef, level > 1 && isEnabled);

  return (
    <RovingIndexContainer isEnabled={isEnabled} ref={parentRef}>
      {(rovingContainer) => (
        <ul {...navItems} {...rovingContainer} className={wrapperClassName}>
          {pages.map((page) => (
            <li role="none" className={styles.item} key={page.flatData.alias}>
              {page.referencingPagesContents?.length ? (
                <NavSubmenu
                  parent={page}
                  level={level + 1}
                  pages={page.referencingPagesContents}
                />
              ) : (
                <NavItem page={page} level={level} />
              )}
            </li>
          ))}
        </ul>
      )}
    </RovingIndexContainer>
  );
});
