import type { InputProps } from '../types';

import { Input } from './input';

export type Props = InputProps<string>;

export const TextField = ({ onChange, ...props }: Props) => (
  <Input
    {...props}
    type="text"
    onChange={(event) => onChange?.(event.target.value)}
  />
);
