import { InlineSVG, SROnly, Text, Trans } from '../components';
import { useClassName, useLiveStatus, useTranslate } from '../hooks';

import styles from './facility.module.scss';

export type Props = {
  facility: Queries.Squidex_Facilities;
  statusVisible?: boolean;
};

export const Facility = ({ facility, statusVisible }: Props) => {
  const translate = useTranslate();
  const liveStatus = useLiveStatus();

  const wrapperClassName = useClassName([
    styles.wrapper,
    facility.flatData.status ? styles.opened : styles.closed,
  ]);

  const label = translate(
    facility.flatData.status
      ? liveStatus?.data.facilitiesOpened
      : liveStatus?.data.facilitiesClosed,
  );

  return (
    <div className={wrapperClassName}>
      <InlineSVG
        className={styles.icon}
        svg={facility.flatData?.icon?.[0]?.localFile?.svg?.content}
      />
      <span
        className={styles.text}
        aria-describedby={statusVisible ? undefined : facility.id}
      >
        <Trans data={facility.data?.name} />
      </span>
      {statusVisible ? (
        <Text size="fs-1" id={facility.id}>
          {label}
        </Text>
      ) : (
        <SROnly id={facility.id}>{label}</SROnly>
      )}
    </div>
  );
};
