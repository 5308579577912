import { navigate } from 'gatsby';
import type { AriaAttributes, ReactNode, MouseEvent } from 'react';
import { Fragment, useMemo } from 'react';

import { Locale } from '../../config';
import { useAccessibility, useLocale } from '../../hooks';

type LinkProps = {
  href: string;
  lang: string;
  hrefLang: string;
  isActive: boolean;
  onClick: (event: MouseEvent) => void;
} & Pick<AriaAttributes, 'aria-label'>;

type Props = {
  children: (props: LinkProps) => ReactNode;
};

export const Languages = ({ children }: Props) => {
  const { locale: current, locales, setLocale, defaultLocale } = useLocale();
  const accessibility = useAccessibility();

  const languages = accessibility?.data.languages?.[current] || [];
  const labels = useMemo(
    () =>
      languages.reduce(
        (labels, { id, label }, key) => ({ ...labels, [id || key]: label }),
        {} as Record<Locale, string>,
      ),
    [languages],
  );

  return (
    <Fragment>
      {locales.map((locale) => {
        const isActive = locale === current;
        const href = locale === defaultLocale ? `/` : `/${locale}`;
        const onClick: LinkProps['onClick'] = async (event) => {
          event.preventDefault();

          setLocale(locale);
          await navigate(href);
        };

        return children({
          href,
          onClick,
          isActive,
          lang: locale,
          hrefLang: locale,
          'aria-label': labels[locale],
        });
      })}
    </Fragment>
  );
};
