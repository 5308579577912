import { createContext, useState, useMemo } from 'react';
import type { ReactNode } from 'react';

export const THEME = {
  DARK: `dark`,
  LIGHT: `light`,
} as const;
export type Theme = (typeof THEME)[keyof typeof THEME];

type Props = {
  theme?: Theme;
  children: ReactNode;
};

type Context = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const DEFAULT_CONTEXT: Context = {
  theme: THEME.LIGHT,
  setTheme: () => {},
};

export const ThemeProvider = ({
  theme: initialTheme = THEME.LIGHT,
  ...props
}: Props) => {
  const [theme, setTheme] = useState<Theme>(initialTheme);

  console.log(`provider: ${theme}`);
  const value = useMemo(
    () => ({
      theme,
      setTheme: (theme: Theme) => {
        console.log(`set: ${theme}`);
        setTheme(theme);
      },
    }),
    [theme],
  );

  return <ThemeContext.Provider value={value} {...props} />;
};

export const ThemeContext = createContext<Context>(DEFAULT_CONTEXT);
