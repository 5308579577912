import type { GatsbyBrowser, GatsbySSR } from 'gatsby';

import type { PageContext, PageData } from './src/config';
import { LocaleProvider } from './src/contexts';
import { Layout } from './src/theme';

type WrapPageElement =
  | GatsbyBrowser<PageData, PageContext>['wrapPageElement']
  | GatsbySSR<PageData, PageContext>['wrapPageElement'];

export const wrapPageElement: WrapPageElement = ({ props, element }) => (
  <LocaleProvider locale={props.pageContext.locale}>
    {typeof element.type === `function` && `SKIP_LAYOUT` in element.type ? (
      element
    ) : (
      <Layout {...props}>{element}</Layout>
    )}
  </LocaleProvider>
);
