import { createContext } from 'react';
import type { AriaAttributes } from 'react';

type Context = {
  direction: Extract<
    AriaAttributes['aria-orientation'],
    'horizontal' | 'vertical'
  >;
  selectTab: (index: number) => void;
  isTabSelected: (index: number) => boolean;
  makeTabId: (index: number) => string;
  makePanelId: (index: number) => string;
};

export const TabsContext = createContext<Context>({
  direction: `horizontal`,
  selectTab: () => {},
  isTabSelected: () => false,
  makeTabId: (index) => `tab${index}`,
  makePanelId: (index) => `panel${index}`,
});
