import type { ReactElement } from 'react';

import { RovingIndexContainer, RovingIndexItem } from '../../components';
import {
  useAccessibility,
  useLocale,
  usePageByAlias,
  useToolbar,
  useTranslate,
} from '../../hooks';
import { TicketsIcon } from '../../icons';

import { Logo } from './logo';
import styles from './toolbar.module.scss';

type Props = {
  menuToggle: ReactElement;
  liveToggle: ReactElement;
};

export const Toolbar = ({ menuToggle, liveToggle }: Props) => {
  const { locale } = useLocale();
  const translate = useTranslate();
  const accessibility = useAccessibility();
  const ticketPage = usePageByAlias(`tickets`);
  const { containerProps } = useToolbar({
    label: translate(accessibility?.data.quickInfoMenu),
  });

  return (
    <div {...containerProps} className={styles.wrapper}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <RovingIndexContainer direction="horizontal">
        {(rovingContainer) => (
          <div className={styles.buttons} {...rovingContainer}>
            <div className={styles.button}>
              <RovingIndexItem>
                {(rovingItem) => (
                  <liveToggle.type {...liveToggle.props} {...rovingItem} />
                )}
              </RovingIndexItem>
            </div>
            <div className={styles.button}>
              <RovingIndexItem>
                {(rovingItem) => (
                  <a
                    href={translate(ticketPage?.data.path)}
                    hrefLang={locale}
                    lang={locale}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tickets}
                    title={translate(ticketPage?.data.description)}
                    aria-label={translate(ticketPage?.data.description)}
                    {...rovingItem}
                  >
                    <TicketsIcon className={styles.icon} aria-hidden={true} />
                  </a>
                )}
              </RovingIndexItem>
            </div>
            <div className={styles.button}>
              <RovingIndexItem>
                {(rovingItem) => (
                  <menuToggle.type {...menuToggle.props} {...rovingItem} />
                )}
              </RovingIndexItem>
            </div>
          </div>
        )}
      </RovingIndexContainer>
    </div>
  );
};
