import { useContext } from 'react';
import type { ReactNode } from 'react';

import { useButton } from '../../hooks';
import { ArrowIcon } from '../../icons';

import { CarouselContext } from './carousel-context';
import styles from './carousel-next.module.scss';

type Props = {
  label?: string;
  children?: ReactNode;
};

export const CarouselNext = ({
  label = `Next Slide`,
  children = <ArrowIcon className={styles.icon} />, // `\u2192`,
}: Props) => {
  const { hasNext, nextItem } = useContext(CarouselContext);

  const button = useButton({ label, isDisabled: !hasNext });

  return (
    <button {...button} onClick={nextItem} className={styles.wrapper}>
      {children}
    </button>
  );
};
