import { useLiveStatus, useTranslate } from '../hooks';

import styles from './facilities.module.scss';
import { Facility } from './facility';


type Props = {
  facilities: readonly Queries.Squidex_Facilities[];
  statusVisible?: boolean;
};

export const Facilities = ({ facilities, statusVisible }: Props) => {
  const translate = useTranslate();
  const liveStatus = useLiveStatus();

  return facilities.length > 0 ? (
    <div
      className={styles.wrapper}
      aria-label={translate(liveStatus?.data.facilitiesTitle)}
    >
      {facilities?.map((facility) => (
        <Facility
          key={facility.id}
          facility={facility}
          statusVisible={statusVisible}
        />
      ))}
    </div>
  ) : null;
};
