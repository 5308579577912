import { graphql, useStaticQuery } from 'gatsby';

export const useAccessibility = ():
  | Queries.Squidex_Accessibilty
  | undefined => {
  const data = useStaticQuery<Queries.Query>(query);

  return data.squidex.queryAccessibiltyContents?.[0];
};

const query = graphql`
  query useAccessibilityQuery {
    squidex {
      queryAccessibiltyContents {
        data {
          notfoundTitle {
            de
            en
          }
          notfoundMessage {
            de
            en
          }
          menuToggleOpen {
            de
            en
          }
          menuToggleClose {
            de
            en
          }
          backToHome {
            de
            en
          }
          backToOverview {
            de
            en
          }
          menuMainLabel {
            de
            en
          }
          menuFooterLabel {
            de
            en
          }
          menuSkipToContent {
            de
            en
          }
          quickInfoMenu {
            de
            en
          }
          moreDetails {
            de
            en
          }
          slide {
            de
            en
          }
          slides {
            de
            en
          }
          slidePrev {
            de
            en
          }
          slideNext {
            de
            en
          }
          validationMessage {
            de
            en
          }
          hideMessage {
            de
            en
          }
          close {
            de
            en
          }
          cancel {
            de
            en
          }
          confirm {
            de
            en
          }
          openFullscreen {
            de
            en
          }
          closeFullscreen {
            de
            en
          }
          shrink {
            de
            en
          }
          enlarge {
            de
            en
          }
          cookiesPlaceholder {
            de
            en
          }
          cookiesButton {
            de
            en
          }
          new {
            de
            en
          }
          languages {
            de {
              id
              label
            }
            en {
              id
              label
            }
          }
        }
        flatData {
          brandName
          companyName
        }
      }
    }
  }
`;
