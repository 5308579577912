import type { ReactElement, ReactNode } from 'react';

import { useButton, useClassName } from '../../hooks';

import styles from './button.module.scss';
import { Text } from './text';
import type { Props as TextProps } from './text';


export type Props<T = unknown> = Parameters<typeof useButton>[0] &
  Pick<TextProps, 'size' | 'color' | 'family' | 'weight' | 'className'> & {
    children: ReactNode;
    wrapper?: 'button' | ReactElement<T>;
  };

export function Button<T>({
  children,
  color = `primary`,
  family = `ff-1`,
  size = `fs-2`,
  weight = undefined,
  className = undefined,
  wrapper = `button`,
  ...props
}: Props<T>) {
  const buttonProps = useButton(props);
  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[color],
    props.isDisabled ? styles.disabled : ``,
    props.isPressed ? styles.pressed : ``,
    className,
  ]);
  const element = wrapper === `button` ? <button {...buttonProps} /> : wrapper;

  return (
    <element.type {...element.props} className={wrapperClassName}>
      <Text
        size={size}
        family={family}
        weight={weight}
        className={styles.text}
        wrapper="span"
      >
        {children}
      </Text>
    </element.type>
  );
}
