import { useContext } from 'react';
import type { ReactNode } from 'react';

import { useButton } from '../../hooks';
import { ArrowIcon } from '../../icons';

import { CarouselContext } from './carousel-context';
import styles from './carousel-prev.module.scss';

type Props = {
  label?: string;
  children?: ReactNode;
};

export const CarouselPrev = ({
  label = `Previous Slide`,
  children = <ArrowIcon className={styles.icon} />, // `\u2190`,
}: Props) => {
  const { hasPrev, prevItem } = useContext(CarouselContext);

  const button = useButton({ label, isDisabled: !hasPrev });

  return (
    <button {...button} onClick={prevItem} className={styles.wrapper}>
      {children}
    </button>
  );
};
