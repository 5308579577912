import { Fragment } from 'react';

import { useTranslate } from '../../hooks';

type Props = {
  data: Parameters<ReturnType<typeof useTranslate>>[0];
};

export const Trans = ({ data }: Props) => {
  const translate = useTranslate();

  return <Fragment>{translate(data)}</Fragment>;
};
