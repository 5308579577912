import { Link } from 'gatsby';

import { useAccessibility, usePageByAlias, useTranslate } from '../../hooks';
import { LogoIcon } from '../../icons';

import styles from './logo.module.scss';

export const Logo = () => {
  const translate = useTranslate();
  const accessibility = useAccessibility();
  const homePage = usePageByAlias(`home`);

  return (
    <Link
      className={styles.wrapper}
      to={translate(homePage?.data.path)}
      aria-label={translate(accessibility?.data.backToHome)}
    >
      <LogoIcon
        className={styles.icon}
        aria-label={translate(accessibility?.data.brandName)}
      />
    </Link>
  );
};
