import { Link } from 'gatsby';
import type { HTMLAttributes } from 'react';

import { RovingIndexItem, Text } from '../../components';
import { useClassName, useMenuItem, useTranslate } from '../../hooks';

import styles from './nav-item.module.scss';

type Props = HTMLAttributes<HTMLUListElement> & {
  page: Queries.Squidex_Pages;
  level?: number;
};

export const NavItem = ({ page, className, level = 1 }: Props) => {
  const menuItem = useMenuItem();
  const translate = useTranslate();

  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[`level${level}`],
    className,
  ]);

  const path = translate(page.data.path);
  const title = translate(page.data.title);
  const size = level === 1 ? `fs-3` : `fs-2`;

  return (
    <RovingIndexItem>
      {(rovingItem) => (
        <Link
          {...menuItem}
          {...rovingItem}
          to={path}
          className={wrapperClassName}
          activeClassName={styles.active}
        >
          <Text size={size} wrapper="span">
            {title}
          </Text>
        </Link>
      )}
    </RovingIndexItem>
  );
};
