import type { ComponentProps, ReactNode } from 'react';

import { Container, Section } from '../components';
import { useClassName } from '../hooks';

import styles from './page-content.module.scss';

type Props = {
  children: ReactNode;
  theme?: 'light' | 'dark' | 'primary';
  layout?: 'default' | 'fullscreen';
} & Pick<
  ComponentProps<typeof Section>,
  'size' | 'paddingTop' | 'paddingBottom'
>;

export const PageContent = ({
  children,
  size = `lg`,
  theme = `light`,
  layout = `default`,
  ...sectionProps
}: Props) => {
  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[theme],
    styles[layout],
  ]);

  switch (layout) {
    case `fullscreen`:
      return (
        <Section {...sectionProps} className={wrapperClassName} size={size}>
          {children}
        </Section>
      );

    default:
      return (
        <Section {...sectionProps} className={wrapperClassName} size={size}>
          <Container>{children}</Container>
        </Section>
      );
  }
};
