import { graphql } from 'gatsby';
import { Fragment } from 'react';

import { Carousel, Markdown, InlineSVG, Title, Trans } from '../components';
import { useAccessibility, useTranslate, useTranslateArray } from '../hooks';

import styles from './arolla-pine-trail.module.scss';

type Props = {
  arollaPineTrails: Queries.Maybe<readonly Queries.Squidex_ArollaPineTrail[]>;
};

export const ArollaPineTrail = ({ arollaPineTrails }: Props) => {
  const translate = useTranslate();
  const translateArray = useTranslateArray();
  const accessibility = useAccessibility();

  return (
    <Carousel autoHeight={true}>
      <Carousel.Items>
        {(arollaPineTrails || []).map((arollaPineTrail, index) => (
          <Carousel.Item
            key={index}
            label={translate(arollaPineTrail.data.title)}
          >
            <div className={styles.wrapper}>
              <InlineSVG
                wrapper="div"
                svg={arollaPineTrail.flatData.map?.[0].localFile?.svg?.content}
                className={styles.image}
              />
              <Title wrapper="h3">
                <Trans data={arollaPineTrail.data.title} />
              </Title>
              <dl>
                {translateArray(arollaPineTrail.data.facts).map(
                  (fact, index) => (
                    <Fragment key={index}>
                      <dt>{fact.label}</dt>
                      <dd>{fact.value}</dd>
                    </Fragment>
                  ),
                )}
              </dl>
              <Markdown
                content={translate(arollaPineTrail.data.content)}
                className={styles.content}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel.Items>
      <Carousel.Controls>
        <Carousel.Prev label={translate(accessibility?.data?.slidePrev)} />
        <Carousel.Tabs label={translate(accessibility?.data?.slides)}>
          {(index) => (
            <Carousel.Tab
              label={`${translate(accessibility?.data?.slide)} ${index + 1}`}
            />
          )}
        </Carousel.Tabs>
        <Carousel.Next label={translate(accessibility?.data?.slideNext)} />
      </Carousel.Controls>
    </Carousel>
  );
};

export const query = graphql`
  fragment ArollaPineTrail on Squidex {
    queryArollaPineTrailContents(orderby: "data/rank/iv") {
      data {
        title {
          de
          en
        }

        facts {
          de {
            label
            value
          }
          en {
            label
            value
          }
        }
        content {
          de
          en
        }
      }

      flatData {
        map {
          localFile {
            svg {
              content: originalContent
            }
          }
          ...SquidexAssetLocalFileProps
        }
      }
    }
  }
`;
