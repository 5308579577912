import { useEffect, useRef } from 'react';

import {
  useAccessibility,
  useButton,
  useClassName,
  useFullscreen,
  useTranslate,
} from '../hooks';

import styles from './webcam-interactive.module.scss';

export type Props = {
  scrollIntoView: boolean;
  webcam: Queries.Squidex_Webcams;
};

export const WebcamInteractive = ({ webcam, scrollIntoView }: Props) => {
  const translate = useTranslate();
  const accessibility = useAccessibility();

  const mountedRef = useRef<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const viewportRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { isFullscreen, toggleFullscreen, supportsFullscreen } = useFullscreen({
    ref: viewportRef,
  });

  const wrapperClassName = useClassName([
    styles.wrapper,
    supportsFullscreen ? styles.fullscreen : ``,
  ]);

  const iframeSrc = webcam.flatData.webcamUrl ?? undefined;
  const button = useButton({
    isPressed: isFullscreen,
    onToggle: toggleFullscreen,
    isDisabled: !supportsFullscreen,
    label: isFullscreen
      ? translate(accessibility?.data.closeFullscreen)
      : translate(accessibility?.data.openFullscreen),
  });

  useEffect(() => {
    if (!scrollIntoView) {
      return;
    }

    buttonRef.current?.scrollIntoView({ behavior: `smooth` });
  }, [scrollIntoView]);

  useEffect(() => {
    if (!mountedRef.current || isFullscreen) {
      return;
    }

    setTimeout(() => {
      buttonRef.current?.scrollIntoView();
    }, 250);
  }, [isFullscreen]);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  return (
    <button ref={buttonRef} {...button} className={wrapperClassName}>
      <div ref={viewportRef} className={styles.viewport}>
        <iframe
          role="img"
          src={iframeSrc}
          ref={iframeRef}
          className={styles.iframe}
          aria-label={translate(webcam.data.name)}
        />
      </div>
    </button>
  );
};
