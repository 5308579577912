import { isArray, stringify } from '../utils';

import { Image } from './image';
import styles from './image-gallery.module.scss';

type Props = {
  label: string | null | undefined;
  images: Queries.Maybe<readonly Queries.Squidex_Asset[]> | undefined;
};

export const ImageGallery = ({ images, label }: Props) =>
  isArray(images) && images.length > 0 ? (
    <div role="img" aria-label={stringify(label)} className={styles.wrapper}>
      {images?.map((image) => (
        <Image alt="" key={image.id} image={image} className={styles.item} />
      ))}
    </div>
  ) : null;
