import type { IframeHTMLAttributes } from 'react';
import { useEffect, useState } from 'react';

import { Button, Text, Trans } from '../components';
import { useAccessibility, useClassName } from '../hooks';
import { isFunction, isObject, stringify } from '../utils';

import styles from './iframe.module.scss';

type Props = {
  src: string | null | undefined;
  title: string | null | undefined;
} & Omit<
  IframeHTMLAttributes<HTMLIFrameElement>,
  'src' | 'title' | 'aria-label'
>;

type API = {
  renew: () => void;
  isReady: () => boolean;
};

const Cookiebot: API = {
  renew: () => {
    if (
      `Cookiebot` in window &&
      isObject(window.Cookiebot) &&
      `renew` in window.Cookiebot &&
      isFunction(window.Cookiebot.renew)
    ) {
      window.Cookiebot.renew();
    }
  },
  isReady: () => `Cookiebot` in window,
};

export const Iframe = ({ src, title, className = ``, ...props }: Props) => {
  const accessibility = useAccessibility();
  const [isReady, setIsReady] = useState<boolean>(false);
  const iframeClassName = useClassName([styles.iframe, className]);

  useEffect(() => {
    setIsReady(Cookiebot.isReady());
    const handler = () => setIsReady(true);

    window.addEventListener(`CookiebotOnLoad`, handler);
    return () => {
      window.removeEventListener(`CookiebotOnLoad`, handler);
    };
  }, []);

  return src ? (
    <div className={styles.wrapper}>
      {isReady ? (
        <div className="cookieconsent-optout-marketing">
          <div className={styles.cookies}>
            <Text wrapper="div">
              <Trans data={accessibility?.data.cookiesPlaceholder} />
            </Text>
            <Button onClick={() => Cookiebot.renew()}>
              <Trans data={accessibility?.data.cookiesButton} />
            </Button>
          </div>
        </div>
      ) : null}
      <iframe
        data-cookieblock-src={src}
        data-cookieconsent="preferences"
        aria-label={stringify(title)}
        className={iframeClassName}
        {...props}
      />
    </div>
  ) : null;
};
