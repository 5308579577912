import { graphql, useStaticQuery } from 'gatsby';

export const useWeather = (): Queries.Squidex_Weather | undefined => {
  const data = useStaticQuery<Queries.Query>(query);

  return data.squidex.queryWeatherContents?.[0];
};

const query = graphql`
  query useWeatherQuery {
    squidex {
      queryWeatherContents {
        data {
          todayOverall {
            de {
              ...WeatherDay
            }
            en {
              ...WeatherDay
            }
          }

          todayMorning {
            de {
              ...WeatherDay
            }
            en {
              ...WeatherDay
            }
          }
          todayNoon {
            de {
              ...WeatherDay
            }
            en {
              ...WeatherDay
            }
          }
          todayEvening {
            de {
              ...WeatherDay
            }
            en {
              ...WeatherDay
            }
          }

          forecastDay1 {
            de {
              ...WeatherDay
            }
            en {
              ...WeatherDay
            }
          }
          forecastDay2 {
            de {
              ...WeatherDay
            }
            en {
              ...WeatherDay
            }
          }
          forecastDay3 {
            de {
              ...WeatherDay
            }
            en {
              ...WeatherDay
            }
          }
        }
        flatData {
          snowDepth
          avalancheStatus
        }
      }
    }
  }

  fragment WeatherDay on Squidex_WeatherDataComponent {
    text
    code {
      id
      data {
        text {
          de
        }
        text {
          en
        }
      }
      flatData {
        icon {
          localFile {
            svg {
              content: originalContent
            }
          }
          ...SquidexAssetLocalFileProps
        }
      }
    }
    newSnow
    windVelocity
    windDirection
    temperatureAvg
    temperatureMin
    temperatureMax
    precipitationProbability
  }
`;
