import { useId, useState } from 'react';

import { useClassName } from '../../hooks';
import { Text } from '../ui';

import styles from './field.module.scss';
import { CheckboxField } from './fields/checkbox';
import type { Props as CheckboxProps } from './fields/checkbox';
import { DateField } from './fields/date';
import type { Props as DateProps } from './fields/date';
import { EmailField } from './fields/email';
import type { Props as EmailProps } from './fields/email';
import { HiddenField } from './fields/hidden';
import type { Props as HiddenProps } from './fields/hidden';
import { NumberField } from './fields/number';
import type { Props as NumberProps } from './fields/number';
import { SelectField } from './fields/select';
import type { Props as SelectProps } from './fields/select';
import { TextField } from './fields/text';
import type { Props as TextProps } from './fields/text';
import { TextAreaField } from './fields/text-area';
import type { Props as TextAreaProps } from './fields/text-area';
import type { BaseFieldProps, FieldProps } from './types';


export const Field = ({
  label,
  input,
  error = undefined,
  submitted = undefined,
  className = undefined,
}: BaseFieldProps) => {
  const {
    props: { id: inputId, value, required },
  } = input;

  const autoId = useId();
  const [touched, setTouched] = useState(false);

  const wrapperClassName = useClassName([
    styles.wrapper,
    error ? styles.error : ``,
    touched ? styles.touched : ``,
    required ? styles.required : ``,
    submitted ? styles.submitted : ``,
    className,
  ]);
  const inputClassName = useClassName([styles.input, input.props.className]);

  const id = inputId || autoId;

  return (
    <div className={wrapperClassName}>
      <input.type
        {...input.props}
        id={id}
        className={inputClassName}
        onBlur={() => setTouched(true)}
        data-value={value}
      />
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <Text size="fs-1" className={styles.message}>
        {error}
      </Text>
    </div>
  );
};

Field.Checkbox = function Checkbox({
  inputProps,
  ...props
}: FieldProps<CheckboxProps>) {
  return <Field {...props} input={<CheckboxField {...inputProps} />} />;
};

Field.Date = function Date({ inputProps, ...props }: FieldProps<DateProps>) {
  return <Field {...props} input={<DateField {...inputProps} />} />;
};

Field.Email = function Email({ inputProps, ...props }: FieldProps<EmailProps>) {
  return <Field {...props} input={<EmailField {...inputProps} />} />;
};

Field.Hidden = function Hidden({
  inputProps,
  ...props
}: FieldProps<HiddenProps>) {
  return <Field {...props} input={<HiddenField {...inputProps} />} />;
};

Field.Number = function Number({
  inputProps,
  ...props
}: FieldProps<NumberProps>) {
  return <Field {...props} input={<NumberField {...inputProps} />} />;
};

Field.Select = function Select<T>({
  inputProps,
  ...props
}: FieldProps<SelectProps<T>>) {
  return <Field {...props} input={<SelectField {...inputProps} />} />;
};

Field.Text = function Text({ inputProps, ...props }: FieldProps<TextProps>) {
  return <Field {...props} input={<TextField {...inputProps} />} />;
};

Field.TextArea = function TextArea({
  inputProps,
  ...props
}: FieldProps<TextAreaProps>) {
  return <Field {...props} input={<TextAreaField {...inputProps} />} />;
};
