import { Link } from 'gatsby';
import { useEffect, useRef } from 'react';
import type { HTMLAttributes } from 'react';

import { RovingIndexItem } from '../../components/';
import {
  useClassName,
  useMenuItem,
  useRovingIndex,
  useTranslate,
} from '../../hooks';

import styles from './nav-item.module.scss';

type Props = HTMLAttributes<HTMLUListElement> & {
  page: Queries.Squidex_Pages;
};

export const NavItem = ({ page, className }: Props) => {
  const menuItem = useMenuItem();
  const translate = useTranslate();
  const wrapperClassName = useClassName([styles.wrapper, className]);

  const path = translate(page.data.path);
  const title = translate(page.data.title);

  return (
    <RovingIndexItem>
      {(rovingItem) => (
        <Link
          {...menuItem}
          {...rovingItem}
          to={path}
          className={wrapperClassName}
          activeClassName={styles.active}
        >
          {title}
        </Link>
      )}
    </RovingIndexItem>
  );
};
