import type { HTMLAttributes } from 'react';

type BaseProps = {
  isDisabled?: boolean;
};

export type Props = BaseProps &
  (
    | {
        isChecked?: never;
        type?: Extract<HTMLAttributes<HTMLElement>['role'], 'menuitem'>;
      }
    | {
        isChecked: boolean;
        type?: Extract<
          HTMLAttributes<HTMLElement>['role'],
          'menuitemcheckbox' | 'menuitemradio'
        >;
      }
  );

export type Result = HTMLAttributes<HTMLElement>;

export const useMenuItem = ({
  type = `menuitem`,
  isChecked = undefined,
  isDisabled = undefined,
}: Props = {}): Result => {
  return {
    role: type,
    'aria-checked': isChecked,
    'aria-disabled': isDisabled,
  };
};
