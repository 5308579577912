import type { HTMLAttributes, ReactNode } from 'react';

import { useClassName } from '../../hooks';

import styles from './sr-only.module.scss';

type Props = {
  children: ReactNode;
} & (
  | ({
      wrapper?: `span`;
    } & HTMLAttributes<HTMLSpanElement>)
  | ({
      wrapper?: `div`;
    } & HTMLAttributes<HTMLDivElement>)
);

export const SROnly = ({
  children,
  wrapper: Wrapper = `span`,
  className = undefined,
  ...domProps
}: Props) => {
  const wrapperClassName = useClassName([styles.wrapper, className]);

  return (
    <Wrapper {...domProps} className={wrapperClassName}>
      {children}
    </Wrapper>
  );
};
