import { createContext } from 'react';

type Context = {
  tabId: string;
  panelId: string;
  isSelected: boolean;
};

export const TabPanelContext = createContext<Context>({
  tabId: ``,
  panelId: ``,
  isSelected: false,
});
