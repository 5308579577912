import { useClassName } from '../../../hooks';
import type { BaseInputProps } from '../types';

import styles from './input.module.scss';

export type Props = BaseInputProps;

export const Input = ({ className = undefined, ...props }: Props) => {
  const wrapperClassName = useClassName([styles.wrapper, className]);

  return <input {...props} className={wrapperClassName} />;
};
