import { InlineSVG, SROnly, Text, Title, Trans } from '../components';
import {
  useFormatDate,
  useLiveStatus,
  useLocale,
  useSettings,
  useTranslate,
  useTranslateObject,
  useWeather,
} from '../hooks';
import { isNumber } from '../utils';

import styles from './weather-overall.module.scss';

const format = (value: unknown, formatter: Intl.NumberFormat): string => {
  if (isNumber(value)) {
    return formatter.format(value);
  }

  return `-`;
};

export const WeatherOverall = () => {
  const translate = useTranslate();
  const translateObject = useTranslateObject();

  const { locale } = useLocale();
  const weather = useWeather();
  const settings = useSettings();
  const liveStatus = useLiveStatus();

  const season = settings?.flatData.season?.[0];
  const today = translateObject(weather?.data.todayOverall);
  const date = useFormatDate({ locale, date: new Date() });

  const celsiusFormatter = new Intl.NumberFormat(locale, {
    style: `unit`,
    unit: `celsius`,
  });
  const percentFormatter = new Intl.NumberFormat(locale, {
    style: `unit`,
    unit: `percent`,
  });
  const centimeterFormatter = new Intl.NumberFormat(locale, {
    style: `unit`,
    unit: `centimeter`,
  });
  const velocityFormatter = new Intl.NumberFormat(locale, {
    style: `unit`,
    unit: `kilometer-per-hour`,
  });

  return (
    <div
      className={styles.wrapper}
      aria-label={translate(liveStatus?.data.weatherTitle)}
    >
      <div className={styles.header}>
        <Title wrapper="h3" className={styles.title}>
          <Trans data={liveStatus?.data.weatherOverall} />
        </Title>
      </div>

      <div className={styles.info}>
        <div className={styles.large}>
          <div className={styles.average}>
            <div className={styles.temperature}>
              <Text
                size="fs-5"
                wrapper="div"
                className={styles.value}
                aria-label={translate(liveStatus?.data.weatherTemperature)}
              >
                {format(today?.temperatureAvg, celsiusFormatter)}
              </Text>

              <div className={styles.boundaries}>
                <Text
                  size="fs-2"
                  className={styles.value}
                  aria-label={translate(liveStatus?.data.weatherTemperature)}
                >
                  {format(today?.temperatureMin, celsiusFormatter)}
                </Text>
                <Text
                  size="fs-2"
                  className={styles.value}
                  aria-label={translate(liveStatus?.data.weatherTemperature)}
                >
                  {format(today?.temperatureMax, celsiusFormatter)}
                </Text>
              </div>
            </div>

            <InlineSVG
              wrapper="div"
              className={styles.icon}
              svg={today?.code?.[0]?.flatData.icon?.[0].localFile?.svg?.content}
            />
          </div>

          <div className={styles.meta}>
            <Text size="fs-1" weight="fw-700">
              <Trans data={liveStatus?.data.weatherMetaInfo} />
            </Text>
          </div>
        </div>
        <div className={styles.small}>
          <div className={styles.block}>
            <div className={styles.item}>
              <SROnly id="wo-date">
                <Trans data={liveStatus?.data.weatherDate} />
              </SROnly>
              <span aria-labelledby="wo-date">{date}</span>
            </div>
            <div className={styles.item}>
              <SROnly id="wo-status">
                <Trans data={liveStatus?.data.weatherStatus} />
              </SROnly>
              <span aria-labelledby="wo-status">
                <Trans data={today?.code?.[0]?.data.text} />
              </span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <span id="wo-rainfall" className={styles.label}>
                <Trans data={liveStatus?.data.weatherRainfall} />
              </span>
              <span aria-labelledby="wo-rainfall">
                {format(today?.precipitationProbability, percentFormatter)}
              </span>
            </div>
            {/* <div className={styles.item}>
              <span id="wo-wind" className={styles.label}>
                <Trans data={liveStatus?.data.weatherWind} />
              </span>
              <span aria-labelledby="wo-wind">
                {format(today?.windVelocity, velocityFormatter)}
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {season?.flatData.alias === `winter` ? (
        <div className={styles.special}>
          <div className={styles.grid}>
            <div className={styles.item}>
              <Text id="wo-snowdepth" weight="fw-700">
                <Trans data={liveStatus?.data.weatherSnowdepth} />
              </Text>
              <span aria-labelledby="wo-snowdepth">
                {format(weather?.flatData.snowDepth, centimeterFormatter)}
              </span>
            </div>
            {/* <div className={styles.item}>
              <Text id="wo-avalanche-status" weight="fw-700">
                <Trans data={liveStatus?.data.weatherAvalanchestatus} />
              </Text>
              <span aria-labelledby="wo-avalanche-status">
                {weather?.flatData.avalancheStatus || `-`}
              </span>
            </div> */}
            <div className={styles.item}>
              <Text id="wo-newsnow" weight="fw-700">
                <Trans data={liveStatus?.data.weatherNewsnow} />
              </Text>
              <span aria-labelledby="wo-avalanche-status">
                {format(today?.newSnow, centimeterFormatter)}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      <div className={styles.summary}>
        <SROnly id="wo-summary">
          <Title wrapper="h4">
            <Trans data={liveStatus?.data.weatherSummary} />
          </Title>
        </SROnly>
        <Text size="fs-1" aria-labelledby="wo-summary">
          {today?.text}
        </Text>
      </div>
    </div>
  );
};
