import { Link } from 'gatsby';
import { useState } from 'react';

import { Trans } from '../components';
import { useClassName, useTranslate } from '../hooks';
import { PlayIcon } from '../icons';

import styles from './webcam-preview.module.scss';

export type Props = {
  webcam: Queries.Squidex_Webcams;
};

export const WebcamPreview = ({ webcam }: Props) => {
  const translate = useTranslate();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const wrapperClassName = useClassName([
    styles.wrapper,
    isLoaded ? styles.loaded : ``,
  ]);

  return (
    <Link to={translate(webcam.data.webcamPath)} className={wrapperClassName}>
      <figure className={styles.figure}>
        <div className={styles.image}>
          {webcam.flatData.thumbnailUrl ? (
            <img
              loading="lazy"
              className={styles.img}
              alt={translate(webcam.data?.name)}
              src={webcam.flatData.thumbnailUrl}
              onLoad={() => setIsLoaded(true)}
            />
          ) : null}
          <PlayIcon aria-hidden={true} className={styles.icon} />
        </div>
        <figcaption className={styles.caption}>
          <Trans data={webcam.data?.name} />
        </figcaption>
      </figure>
    </Link>
  );
};
