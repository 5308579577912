import { createContext, useState, useMemo } from 'react';
import type { ReactNode } from 'react';

import { LOCALES_ARRAY, DEFAULT_LOCALE } from '../../config';
import type { Locale } from '../../config';
import { extractLanguage, isLocale } from '../../utils';

type Props = {
  locale: unknown;
  children: ReactNode;
};

type Context = {
  locale: Locale;
  locales: Locale[];
  defaultLocale: Locale;
  setLocale: (locale: Locale) => void;
};

const DEFAULT_CONTEXT: Context = {
  locale: DEFAULT_LOCALE,
  locales: LOCALES_ARRAY,
  defaultLocale: DEFAULT_LOCALE,
  setLocale: () => {},
};

const validateLocale = (locale: Props['locale']): Locale => {
  if (isLocale(locale)) {
    return locale;
  }

  const detectedLocale = extractLanguage(LOCALES_ARRAY);
  if (isLocale(detectedLocale)) {
    return detectedLocale;
  }

  return DEFAULT_LOCALE;
};

export const LocaleProvider = ({ locale: initialLocale, ...props }: Props) => {
  const [locale, setLocale] = useState(validateLocale(initialLocale));
  const value = useMemo(
    () => ({
      locale,
      locales: LOCALES_ARRAY,
      defaultLocale: DEFAULT_LOCALE,
      setLocale: (locale: Locale) => {
        setLocale(validateLocale(locale));
      },
    }),
    [locale],
  );

  return <LocaleContext.Provider value={value} {...props} />;
};

export const LocaleContext = createContext<Context>(DEFAULT_CONTEXT);
