import { InlineSVG, Text, Title, Trans } from '../components';
import {
  useLiveStatus,
  useLocale,
  useTranslate,
  useTranslateObject,
  useWeather,
} from '../hooks';
import { isNumber } from '../utils';

import styles from './weather-over-the-day.module.scss';

const format = (value: unknown, formatter: Intl.NumberFormat): string => {
  if (isNumber(value)) {
    return formatter.format(value);
  }

  return `-`;
};

export const WeatherOverTheDay = () => {
  const translate = useTranslate();
  const translateObject = useTranslateObject();

  const weather = useWeather();
  const { locale } = useLocale();
  const liveStatus = useLiveStatus();

  const morning = translateObject(weather?.data.todayMorning);
  const noon = translateObject(weather?.data.todayNoon);
  const evening = translateObject(weather?.data.todayEvening);

  const celsiusFormatter = new Intl.NumberFormat(locale, {
    style: `unit`,
    unit: `celsius`,
  });

  return (
    <div
      className={styles.wrapper}
      aria-label={translate(liveStatus?.data.weatherTitle)}
    >
      <div className={styles.header}>
        <Title wrapper="h3" className={styles.title}>
          <Trans data={liveStatus?.data.weatherOverTheDay} />
        </Title>
      </div>

      <div className={styles.items}>
        <div className={styles.item}>
          <Title wrapper="h4" className={styles.title}>
            <Trans data={liveStatus?.data.weatherMorning} />
          </Title>
          <InlineSVG
            wrapper="div"
            className={styles.icon}
            svg={morning?.code?.[0]?.flatData.icon?.[0].localFile?.svg?.content}
          />

          <Text
            size="fs-4"
            className={styles.temperature}
            aria-label={translate(liveStatus?.data.weatherTemperature)}
          >
            {format(morning?.temperatureAvg, celsiusFormatter)}
          </Text>

          <span
            className={styles.status}
            aria-label={translate(liveStatus?.data.weatherStatus)}
          >
            <Trans data={morning?.code?.[0]?.data.text} />
          </span>
        </div>

        <div className={styles.item}>
          <Title wrapper="h4" className={styles.title}>
            <Trans data={liveStatus?.data.weatherNoon} />
          </Title>
          <InlineSVG
            wrapper="div"
            className={styles.icon}
            svg={noon?.code?.[0]?.flatData.icon?.[0].localFile?.svg?.content}
          />

          <Text
            size="fs-4"
            className={styles.temperature}
            aria-label={translate(liveStatus?.data.weatherTemperature)}
          >
            {format(noon?.temperatureAvg, celsiusFormatter)}
          </Text>

          <span
            className={styles.status}
            aria-label={translate(liveStatus?.data.weatherStatus)}
          >
            <Trans data={noon?.code?.[0]?.data.text} />
          </span>
        </div>

        <div className={styles.item}>
          <Title wrapper="h4" className={styles.title}>
            <Trans data={liveStatus?.data.weatherEvening} />
          </Title>
          <InlineSVG
            wrapper="div"
            className={styles.icon}
            svg={evening?.code?.[0]?.flatData.icon?.[0].localFile?.svg?.content}
          />
          <Text
            size="fs-4"
            className={styles.temperature}
            aria-label={translate(liveStatus?.data.weatherTemperature)}
          >
            {format(evening?.temperatureAvg, celsiusFormatter)}
          </Text>
          <span
            className={styles.status}
            aria-label={translate(liveStatus?.data.weatherStatus)}
          >
            <Trans data={evening?.code?.[0]?.data.text} />
          </span>
        </div>
      </div>
    </div>
  );
};
