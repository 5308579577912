import { createContext } from 'react';

import type { SelectionState } from './carousel-constants';
import { SELECTION_STATE } from './carousel-constants';

type Context = {
  label: string;
  itemId: string;
  isSelected: boolean;
  selectionState: SelectionState;
  select: () => void;
  resolve: () => void;
};

export const CarouselItemContext = createContext<Context>({
  label: ``,
  itemId: ``,
  isSelected: false,
  selectionState: SELECTION_STATE.RESOLVED,
  select: () => {},
  resolve: () => {},
});
