import { createContext } from 'react';

import { SELECTION_STATE } from './carousel-constants';
import type { SelectionState } from './carousel-constants';

type Context = {
  itemsId: string;
  itemCount: number;
  hasPrev: boolean;
  hasNext: boolean;
  autoHeight: boolean;
  prevItem: () => void;
  nextItem: () => void;
  selectionState: SelectionState;
  makeItemId: (index: number) => string;
  selectItem: (index: number) => void;
  resolveItem: (index: number) => void;
  isItemSelected: (index: number) => boolean;
};

export const CarouselContext = createContext<Context>({
  itemsId: ``,
  hasNext: false,
  hasPrev: false,
  itemCount: 0,
  selectionState: SELECTION_STATE.RESOLVED,
  autoHeight: false,
  prevItem: () => {},
  nextItem: () => {},
  selectItem: () => {},
  resolveItem: () => {},
  isItemSelected: () => false,
  makeItemId: (index) => `item-${index}`,
});
