import { graphql, useStaticQuery } from 'gatsby';

export const useLiveStatus = (): Queries.Squidex_LiveStatus | undefined => {
  const data = useStaticQuery<Queries.Query>(query);

  return data.squidex.queryLiveStatusContents?.[0];
};

const query = graphql`
  query useLiveStatusQuery {
    squidex {
      queryLiveStatusContents {
        data {
          title {
            de
            en
          }
          label {
            de
            en
          }
          description {
            de
            en
          }
          weatherTitle {
            de
            en
          }
          weatherOverall {
            de
            en
          }
          weatherMetaInfo {
            de
            en
          }
          weatherOverTheDay {
            de
            en
          }
          weatherMorning {
            de
            en
          }
          weatherNoon {
            de
            en
          }
          weatherEvening {
            de
            en
          }
          weatherForecast {
            de
            en
          }
          weatherTemperature {
            de
            en
          }
          weatherDate {
            de
            en
          }
          weatherStatus {
            de
            en
          }
          weatherRainfall {
            de
            en
          }
          weatherWind {
            de
            en
          }
          weatherSnowdepth {
            de
            en
          }
          weatherAvalanchestatus {
            de
            en
          }
          weatherNewsnow {
            de
            en
          }
          weatherSummary {
            de
            en
          }

          facilitiesTitle {
            de
            en
          }
          facilitiesClosed {
            de
            en
          }
          facilitiesOpened {
            de
            en
          }

          slopesTitle {
            de
            en
          }
          slopesClosed {
            de
            en
          }
          slopesOpened {
            de
            en
          }

          webcamsTitle {
            de
            en
          }
          webcamsDescription {
            de
            en
          }
        }
      }
    }
  }
`;
