import { createContext } from 'react';

type Context = {
  itemId: string;
  select: () => void;
  isSelected: boolean;
};

export const CarouselTabContext = createContext<Context>({
  itemId: ``,
  select: () => {},
  isSelected: false,
});
