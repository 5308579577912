exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-date-time-tsx": () => import("./../../../src/pages/date-time.tsx" /* webpackChunkName: "component---src-pages-date-time-tsx" */),
  "component---src-pages-facilities-slopes-tsx": () => import("./../../../src/pages/facilities-slopes.tsx" /* webpackChunkName: "component---src-pages-facilities-slopes-tsx" */),
  "component---src-templates-accessibility-tsx": () => import("./../../../src/templates/accessibility.tsx" /* webpackChunkName: "component---src-templates-accessibility-tsx" */),
  "component---src-templates-alpine-garden-tsx": () => import("./../../../src/templates/alpine-garden.tsx" /* webpackChunkName: "component---src-templates-alpine-garden-tsx" */),
  "component---src-templates-arolla-pine-trail-tsx": () => import("./../../../src/templates/arolla-pine-trail.tsx" /* webpackChunkName: "component---src-templates-arolla-pine-trail-tsx" */),
  "component---src-templates-bee-education-trail-tsx": () => import("./../../../src/templates/bee-education-trail.tsx" /* webpackChunkName: "component---src-templates-bee-education-trail-tsx" */),
  "component---src-templates-children-land-tsx": () => import("./../../../src/templates/children-land.tsx" /* webpackChunkName: "component---src-templates-children-land-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-directions-tsx": () => import("./../../../src/templates/directions.tsx" /* webpackChunkName: "component---src-templates-directions-tsx" */),
  "component---src-templates-events-tsx": () => import("./../../../src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-gastronomy-tsx": () => import("./../../../src/templates/gastronomy.tsx" /* webpackChunkName: "component---src-templates-gastronomy-tsx" */),
  "component---src-templates-group-tours-tsx": () => import("./../../../src/templates/group-tours.tsx" /* webpackChunkName: "component---src-templates-group-tours-tsx" */),
  "component---src-templates-gtc-tsx": () => import("./../../../src/templates/gtc.tsx" /* webpackChunkName: "component---src-templates-gtc-tsx" */),
  "component---src-templates-hiking-trails-tsx": () => import("./../../../src/templates/hiking-trails.tsx" /* webpackChunkName: "component---src-templates-hiking-trails-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-imprint-tsx": () => import("./../../../src/templates/imprint.tsx" /* webpackChunkName: "component---src-templates-imprint-tsx" */),
  "component---src-templates-intersport-tsx": () => import("./../../../src/templates/intersport.tsx" /* webpackChunkName: "component---src-templates-intersport-tsx" */),
  "component---src-templates-jobs-tsx": () => import("./../../../src/templates/jobs.tsx" /* webpackChunkName: "component---src-templates-jobs-tsx" */),
  "component---src-templates-kids-hiking-trails-tsx": () => import("./../../../src/templates/kids-hiking-trails.tsx" /* webpackChunkName: "component---src-templates-kids-hiking-trails-tsx" */),
  "component---src-templates-live-status-tsx": () => import("./../../../src/templates/live-status.tsx" /* webpackChunkName: "component---src-templates-live-status-tsx" */),
  "component---src-templates-mountainbike-tsx": () => import("./../../../src/templates/mountainbike.tsx" /* webpackChunkName: "component---src-templates-mountainbike-tsx" */),
  "component---src-templates-opening-hours-tsx": () => import("./../../../src/templates/opening-hours.tsx" /* webpackChunkName: "component---src-templates-opening-hours-tsx" */),
  "component---src-templates-playground-tsx": () => import("./../../../src/templates/playground.tsx" /* webpackChunkName: "component---src-templates-playground-tsx" */),
  "component---src-templates-prices-tsx": () => import("./../../../src/templates/prices.tsx" /* webpackChunkName: "component---src-templates-prices-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-protect-kofel-tsx": () => import("./../../../src/templates/protect-kofel.tsx" /* webpackChunkName: "component---src-templates-protect-kofel-tsx" */),
  "component---src-templates-quiz-hiking-trail-tsx": () => import("./../../../src/templates/quiz-hiking-trail.tsx" /* webpackChunkName: "component---src-templates-quiz-hiking-trail-tsx" */),
  "component---src-templates-reconstruction-water-supply-tsx": () => import("./../../../src/templates/reconstruction-water-supply.tsx" /* webpackChunkName: "component---src-templates-reconstruction-water-supply-tsx" */),
  "component---src-templates-restaurants-tsx": () => import("./../../../src/templates/restaurants.tsx" /* webpackChunkName: "component---src-templates-restaurants-tsx" */),
  "component---src-templates-seminars-tsx": () => import("./../../../src/templates/seminars.tsx" /* webpackChunkName: "component---src-templates-seminars-tsx" */),
  "component---src-templates-ski-snowboard-tsx": () => import("./../../../src/templates/ski-snowboard.tsx" /* webpackChunkName: "component---src-templates-ski-snowboard-tsx" */),
  "component---src-templates-skischools-tsx": () => import("./../../../src/templates/skischools.tsx" /* webpackChunkName: "component---src-templates-skischools-tsx" */),
  "component---src-templates-skitour-tsx": () => import("./../../../src/templates/skitour.tsx" /* webpackChunkName: "component---src-templates-skitour-tsx" */),
  "component---src-templates-snowpark-tsx": () => import("./../../../src/templates/snowpark.tsx" /* webpackChunkName: "component---src-templates-snowpark-tsx" */),
  "component---src-templates-snowshoeing-tsx": () => import("./../../../src/templates/snowshoeing.tsx" /* webpackChunkName: "component---src-templates-snowshoeing-tsx" */),
  "component---src-templates-trailrunning-tsx": () => import("./../../../src/templates/trailrunning.tsx" /* webpackChunkName: "component---src-templates-trailrunning-tsx" */),
  "component---src-templates-training-race-courses-tsx": () => import("./../../../src/templates/training-race-courses.tsx" /* webpackChunkName: "component---src-templates-training-race-courses-tsx" */)
}

