import { useEffect, useState, useRef } from 'react';

type Props = {
  date: Date;
  locale?: string;
  prefix?: string;
  suffix?: string;
  options?: Intl.DateTimeFormatOptions;
};

const formatTime = (
  date: Date,
  locale?: string,
  options?: Intl.DateTimeFormatOptions,
) => date.toLocaleTimeString(locale, options);

export const useFormatTime = ({
  date,
  locale,
  prefix,
  suffix,
  options = {
    timeStyle: `short`,
  },
}: Props): string => {
  const optionsRef = useRef<Props['options']>(options);
  const [formattedDate, setFormattedDate] = useState(() =>
    formatTime(date, locale, { ...optionsRef.current, timeZone: `UTC` }),
  );

  useEffect(
    () => setFormattedDate(formatTime(date, locale, optionsRef.current)),
    [date, locale],
  );

  return `${prefix ? prefix + ` ` : ``}${formattedDate}${
    suffix ? ` ` + suffix : ``
  }`;
};
