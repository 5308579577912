import type { GatsbyBrowser } from 'gatsby';

import type { PageContext, PageData } from './src/config';
import { wrapPageElement as commonWrapPageElement } from './wrap-page-element';

import './src/styles/main.scss';

export const wrapPageElement: GatsbyBrowser<
  PageData,
  PageContext
>['wrapPageElement'] = commonWrapPageElement;
