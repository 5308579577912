import { useContext, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';

import { useClassName, useTab } from '../../hooks';
import { convertCssUnit } from '../../utils';

import { SELECTION_STATE } from './carousel-constants';
import { CarouselItemContext } from './carousel-item-context';
import styles from './carousel-item.module.scss';

type Props = {
  label: string;
  children: ReactNode;
};

const SCROLL_OFFSET_VARIABLE = `--carousel-offset-y`;
const getScrollOffset = (element: HTMLElement) =>
  convertCssUnit(
    getComputedStyle(element).getPropertyValue(SCROLL_OFFSET_VARIABLE),
  ) || 0;

export const CarouselItem = ({ label, children }: Props) => {
  const {
    itemId,
    select,
    resolve,
    isSelected,
    selectionState,
    label: defaultLabel,
  } = useContext(CarouselItemContext);

  const selectRef = useRef<typeof select>(select);
  const resolveRef = useRef<typeof resolve>(resolve);
  const selectionStateRef = useRef<typeof selectionState>(selectionState);
  const wrapperRef = useRef<HTMLDivElement>(null);

  selectRef.current = select;
  resolveRef.current = resolve;
  selectionStateRef.current = selectionState;

  const { controlProps } = useTab({
    isSelected,
    controlId: itemId,
    controlClassName: styles.wrapper,
    controlSelectedClassName: useClassName([styles.wrapper, styles.active]),
  });

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }

    // auto select item if scrolled/snapped into view by user
    // if 50% visible AND no manual selection (isPending=false)
    const observer = new IntersectionObserver(
      ([entry]) => {
        switch (selectionStateRef.current) {
          case SELECTION_STATE.RESOLVED:
            if (entry?.isIntersecting) {
              resolveRef.current();
            }

            break;
          default:
        }
      },
      { root: wrapper.parentElement, threshold: 1 },
    );

    observer.observe(wrapper);
    return () => {
      observer.unobserve(wrapper);
    };
  }, []);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!isSelected || !wrapper) {
      return;
    }

    switch (selectionStateRef.current) {
      case SELECTION_STATE.SELECTED: {
        // selected item needs to be scrolled into view and marked
        // as resolved, as soon as the item is in current viewport
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry?.isIntersecting) {
              // `scroll-margin-top` buggy, fix with window.scrollBy()
              // const scrollOffset = getScrollOffset(wrapper);
              // window.scrollBy(0, -1 * scrollOffset);

              // finally mark as resolved to enable other items again
              resolveRef.current();
            }
          },
          { root: wrapper.parentElement, threshold: 1 },
        );

        observer.observe(wrapper);
        wrapper.scrollIntoView();

        return () => {
          observer.unobserve(wrapper);
        };
      }
      default:
    }
  }, [isSelected]);

  return (
    <div
      {...controlProps}
      ref={wrapperRef}
      aria-roledescription="slide"
      aria-label={label || defaultLabel}
    >
      {children}
    </div>
  );
};
