import type { HTMLAttributes } from 'react';

import { useClassName } from '../../hooks';
import type { Breakpoint } from '../../types';

import styles from './divider.module.scss';

export type Props = {
  size?: 'none' | Extract<Breakpoint, 'sm' | 'md' | 'lg'>;
  color?: 'black' | 'white' | 'grey';
} & HTMLAttributes<HTMLHRElement>;

export const Divider = ({
  size = `md`,
  color = `black`,
  className = undefined,
  ...props
}: Props) => {
  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[size],
    styles[color],
    className,
  ]);

  return <hr {...props} className={wrapperClassName} />;
};
