import {
  useAccessibility,
  useMenu,
  usePagesByView,
  useRootPages,
  useTranslate,
} from '../../hooks';

import { NavItems } from './nav-items';

type Props = {
  isEnabled: boolean;
};

export const Nav = ({ isEnabled }: Props) => {
  const translate = useTranslate();
  const rootPages = useRootPages();
  const accessibility = useAccessibility();

  const pages = usePagesByView(`main`, rootPages);

  const menuProps = useMenu({
    label: translate(accessibility?.data.menuMainLabel),
  });

  return <NavItems {...menuProps} pages={pages} isEnabled={isEnabled} />;
};
