import { useEffect, useRef, useId, useState } from 'react';
import type {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  CSSProperties,
} from 'react';

import { useClassName } from '../../hooks';
import { isString, isUndefined } from '../../utils';

import styles from './tooltip.module.scss';

const TOOLTIP_POSITION = {
  TOP: `top`,
  BOTTOM: `bottom`,
} as const;
type TooltipPosition = (typeof TOOLTIP_POSITION)[keyof typeof TOOLTIP_POSITION];

type Props = {
  children: (props: HTMLAttributes<HTMLElement>) => ReactElement;
  isVisible?: boolean;
  position?: TooltipPosition;
} & (
  | {
      label?: ReactNode;
      description?: never;
    }
  | {
      label?: never;
      description?: ReactNode;
    }
);

export const Tooltip = ({
  children,
  label,
  description,
  isVisible: initialIsVisible = false,
  position: initialPosition = TOOLTIP_POSITION.TOP,
}: Props) => {
  const tooltipId = useId();
  const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible);
  const [tooltipStyles, setTooltipStyles] = useState<CSSProperties>({});
  const [tooltipPosition, setTooltipPosition] =
    useState<TooltipPosition>(initialPosition);

  const tooltipRef = useRef<HTMLParagraphElement>(null);
  const ariaTriggerProps = isString(label)
    ? {
        'aria-labelledby': tooltipId,
      }
    : {
        'aria-describedby': tooltipId,
      };
  const domTriggerProps = {
    onBlur: () => hideTooltip(),
    onFocus: () => showTooltip(),
  };

  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[tooltipPosition],
    isVisible ? styles.visible : ``,
  ]);

  const moveTooltipUp = () => setTooltipPosition(TOOLTIP_POSITION.TOP);
  const moveTooltipDown = () => setTooltipPosition(TOOLTIP_POSITION.BOTTOM);

  const moveTooltipLeft = (bounds: DOMRect) => {
    const numToMove = -Math.floor(bounds.width / 2);

    setTooltipStyles((styles) => ({
      ...styles,
      left: `auto`,
      right: `${numToMove}px`,
    }));
  };

  const moveTooltipRight = (bounds: DOMRect) => {
    const numToMove = Math.floor(bounds.width / 2);

    setTooltipStyles((styles) => ({
      ...styles,
      right: `auto`,
      left: `${numToMove}px`,
    }));
  };

  const checkHorizontalBounding = (bounds: DOMRect) => {
    if (bounds.right > window.outerWidth) {
      moveTooltipLeft(bounds);
    } else if (bounds.left < 0) {
      moveTooltipRight(bounds);
    }
  };

  const checkVerticalBounding = (bounds: DOMRect) => {
    if (bounds.bottom > window.outerWidth) {
      moveTooltipUp();
    } else if (bounds.top < 0) {
      moveTooltipDown();
    }
  };

  const showTooltip = () => {
    const bounds = tooltipRef.current?.getBoundingClientRect();
    if (isUndefined(bounds)) {
      return;
    }

    setIsVisible(true);
    checkVerticalBounding(bounds);
    checkHorizontalBounding(bounds);
  };

  const hideTooltip = () => {
    setIsVisible(false);
    setTooltipStyles({});
  };

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const keyDownHandler = (event: KeyboardEvent) => {
      switch (event.key) {
        case `Escape`:
          event.preventDefault();
          hideTooltip();
          break;
        default:
      }
    };

    document.addEventListener(`keydown`, keyDownHandler);

    return () => {
      document.removeEventListener(`keydown`, keyDownHandler);
    };
  }, [isVisible]);

  return (
    <div
      className={wrapperClassName}
      onMouseEnter={() => showTooltip()}
      onMouseLeave={() => hideTooltip()}
      onTouchStart={() => showTooltip()}
    >
      {children({
        ...ariaTriggerProps,
        ...domTriggerProps,
      })}

      <div ref={tooltipRef} style={tooltipStyles} className={styles.tooltip}>
        <div
          role="tooltip"
          id={tooltipId}
          className={styles.holder}
          aria-hidden={!isVisible}
        >
          {label || description}
        </div>
      </div>
    </div>
  );
};
