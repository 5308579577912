import type { HTMLAttributes, ReactNode } from 'react';

import { useClassName } from '../../hooks';

import styles from './pre-wrap.module.scss';

type Props = {
  children?: ReactNode;
} & (
  | ({
      wrapper?: `div`;
    } & HTMLAttributes<HTMLDivElement>)
  | ({
      wrapper?: `span`;
    } & HTMLAttributes<HTMLSpanElement>)
  | ({
      wrapper?: `p`;
    } & HTMLAttributes<HTMLParagraphElement>)
);

export const PreWrap = ({
  className,
  wrapper: Wrapper = `div`,
  ...props
}: Props) => {
  const wrapperClassName = useClassName([styles.wrapper, className]);
  return <Wrapper {...props} className={wrapperClassName} />;
};
