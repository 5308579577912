import { useState } from 'react';
import type { ComponentProps } from 'react';

import { TabsControlled } from './tabs-controlled';

type TabsProps = ComponentProps<typeof TabsControlled>;

type Props = Omit<TabsProps, 'selectedIndex' | 'onSelect'> &
  Partial<Pick<TabsProps, 'selectedIndex' | 'onSelect'>>;

export const Tabs = ({
  selectedIndex: initialSelectedIndex = 0,
  onSelect = () => {},
  ...props
}: Props) => {
  const [selectedIndex, setSelectedIndex] =
    useState<TabsProps['selectedIndex']>(initialSelectedIndex);

  const selectHandler: TabsProps['onSelect'] = (index) => {
    setSelectedIndex(index);
    onSelect?.(index);
  };

  return (
    <TabsControlled
      {...props}
      selectedIndex={selectedIndex}
      onSelect={selectHandler}
    />
  );
};

Tabs.TabList = TabsControlled.TabList;
Tabs.Tab = TabsControlled.Tab;

Tabs.TabPanels = TabsControlled.TabPanels;
Tabs.TabPanel = TabsControlled.TabPanel;
