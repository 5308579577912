import type { InputProps } from '../types';

import { Input } from './input';

export type Props = InputProps<number>;

export const NumberField = ({ onChange, ...props }: Props) => (
  <Input
    min="0"
    step="1"
    type="number"
    inputMode="decimal"
    onChange={(event) => onChange?.(event.target.valueAsNumber)}
    {...props}
  />
);
