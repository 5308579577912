import { createContext } from 'react';

type Context = {
  toggle: () => void;
  isSelected: boolean;
  theme: 'dark' | 'light';
};

export const AccordionItemContext = createContext<Context>({
  toggle: () => {},
  isSelected: false,
  theme: `dark`,
});
