import { GatsbyImage } from 'gatsby-plugin-image';
import type { ComponentProps } from 'react';

import { stringify } from '../utils';

type Props = {
  alt: string | null | undefined;
  image: Queries.Maybe<Queries.Squidex_Asset> | undefined;
} & Omit<ComponentProps<typeof GatsbyImage>, 'image' | 'alt'>;

export const Image = ({ alt, image, ...props }: Props) => {
  const data = image?.localFile?.childImageSharp?.gatsbyImageData;

  return data ? (
    <GatsbyImage image={data} alt={stringify(alt)} {...props} />
  ) : null;
};
