import { SROnly, Trans, Text } from '../components';
import { useClassName, useLiveStatus, useTranslate } from '../hooks';

import styles from './slope.module.scss';

export type Props = {
  slope: Queries.Squidex_Slopes;
  statusVisible?: boolean;
};

export const Slope = ({ slope, statusVisible }: Props) => {
  const translate = useTranslate();
  const liveStatus = useLiveStatus();

  const wrapperClassName = useClassName([
    styles.wrapper,
    slope.flatData.status ? styles.opened : styles.closed,
  ]);

  const label = translate(
    slope.flatData.status
      ? liveStatus?.data.slopesOpened
      : liveStatus?.data.slopesClosed,
  );

  return (
    <div className={wrapperClassName}>
      <span className={styles.symbol}>{slope.flatData.symbol}</span>
      <span
        className={styles.text}
        aria-describedby={statusVisible ? undefined : slope.id}
      >
        <Trans data={slope.data?.name} />
      </span>

      {statusVisible ? (
        <Text size="fs-1" id={slope.id}>
          {label}
        </Text>
      ) : (
        <SROnly id={slope.id}>{label}</SROnly>
      )}
    </div>
  );
};
