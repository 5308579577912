import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { isActiveSeason } from '../../utils';

import { useSettings } from './use-settings';

export const useSlopes = (): readonly Queries.Squidex_Slopes[] => {
  const {
    squidex: { querySlopesContents: slopes },
  } = useStaticQuery<Queries.Query>(query);

  return useMemo(() => slopes || [], []);
};

export const useActiveSlopes = (): readonly Queries.Squidex_Slopes[] => {
  const settings = useSettings();
  const slopes = useSlopes();

  return useMemo(
    () =>
      slopes.filter((facility) =>
        isActiveSeason(facility.flatData.season, settings),
      ),
    [slopes, settings],
  );
};

const query = graphql`
  query useSlopesQuery {
    squidex {
      querySlopesContents(orderby: "data/rank/iv asc") {
        id
        data {
          name {
            de
            en
          }
        }
        flatData {
          status
          season {
            flatData {
              alias
            }
          }
          symbol
          difficulty {
            flatData {
              value
              color
            }
          }
        }
      }
    }
  }
`;
