import { Fragment, useEffect, useState } from 'react';

import { Section, TabsControlled, Trans } from '../components';
import { useLiveStatus, useTranslate } from '../hooks';

import { WebcamInteractive } from './webcam-interactive';
import styles from './webcams-interactive.module.scss';

type Props = {
  selectedAlias: string | null;
  webcams: readonly Queries.Squidex_Webcams[];
};

export const WebcamsInteractive = ({ webcams, selectedAlias }: Props) => {
  const translate = useTranslate();
  const liveStatus = useLiveStatus();

  const [selectedIndex, setSelectedIndex] = useState<number>(() => {
    const index = webcams.findIndex(
      (webcam) => selectedAlias === translate(webcam.data.alias),
    );
    return index >= 0 ? index : 0;
  });

  const selectHandler = (selectedIndex: number): void => {
    setSelectedIndex(selectedIndex);
  };

  useEffect(() => {
    setSelectedIndex((currentIndex) => {
      const index = webcams.findIndex(
        (webcam) => selectedAlias === translate(webcam.data.alias),
      );

      return index >= 0 ? index : currentIndex;
    });
  }, [webcams, selectedAlias]);

  return (
    <Fragment>
      <Section
        className={styles.wrapper}
        aria-label={translate(liveStatus?.data.webcamsTitle)}
      >
        <TabsControlled onSelect={selectHandler} selectedIndex={selectedIndex}>
          <TabsControlled.TabPanels>
            {webcams.map((webcam, index) => (
              <TabsControlled.TabPanel key={webcam.id}>
                {index === selectedIndex && (
                  <WebcamInteractive
                    webcam={webcam}
                    scrollIntoView={!!selectedAlias}
                  />
                )}
              </TabsControlled.TabPanel>
            ))}
          </TabsControlled.TabPanels>
          <TabsControlled.TabList
            size="fullwidth"
            label={translate(liveStatus?.data.webcamsDescription)}
          >
            {webcams.map((webcam) => (
              <TabsControlled.Tab key={webcam.id}>
                <Trans data={webcam.data.name} />
              </TabsControlled.Tab>
            ))}
          </TabsControlled.TabList>
        </TabsControlled>
      </Section>
    </Fragment>
  );
};
