import { Fragment, Children, isValidElement, useContext } from 'react';
import type { ComponentProps, ReactElement } from 'react';

import { TabPanel } from './tab-panel';
import { TabPanelContext } from './tab-panel-context';
import { TabsContext } from './tabs-context';

type TabPanelProps = ComponentProps<typeof TabPanel>;
type TabPanelType = ReactElement<TabPanelProps>;

type Props = {
  children: TabPanelType | TabPanelType[];
};

export const TabPanels = ({ children }: Props) => {
  const { isTabSelected, makeTabId, makePanelId } = useContext(TabsContext);

  const tabPanels = Children.toArray(children).filter(
    (element: unknown): element is TabPanelType =>
      isValidElement(element) && element.type === TabPanel,
  );

  return (
    <Fragment>
      {tabPanels.map((tabPanel, index) => (
        <TabPanelContext.Provider
          key={makePanelId(index)}
          value={{
            tabId: makeTabId(index),
            panelId: makePanelId(index),
            isSelected: isTabSelected(index),
          }}
        >
          {tabPanel}
        </TabPanelContext.Provider>
      ))}
    </Fragment>
  );
};
