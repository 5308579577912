export const ENVIRONMENT = {
  DEV: `DEV`,
  PROD: `PROD`,
} as const;

export type Environment = (typeof ENVIRONMENT)[keyof typeof ENVIRONMENT];

export const IS_CLIENT = typeof window !== `undefined`;
export const IS_SERVER = !IS_CLIENT;

export const ENV: Environment =
  process.env.NODE_ENV === `prod` ? ENVIRONMENT.PROD : ENVIRONMENT.DEV;
