import { sanitize } from 'isomorphic-dompurify';
import type { HTMLAttributes } from 'react';

import { isString } from '../../utils';

type Props = {
  content?: string | null;
} & (
  | ({
      wrapper?: `div`;
    } & Omit<HTMLAttributes<HTMLDivElement>, 'content'>)
  | ({
      wrapper?: `span`;
    } & Omit<HTMLAttributes<HTMLSpanElement>, 'content'>)
  | ({
      wrapper?: `p`;
    } & Omit<HTMLAttributes<HTMLParagraphElement>, 'content'>)
);

export const HTML = ({ content, wrapper: Wrapper = `div`, ...props }: Props) =>
  isString(content) && content.length > 0 ? (
    <Wrapper
      {...props}
      dangerouslySetInnerHTML={{
        __html: sanitize(content, {
          RETURN_DOM: false,
          USE_PROFILES: { html: true },
        }),
      }}
    />
  ) : null;
