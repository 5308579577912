import { useRef, useEffect } from 'react';
import type { RefObject } from 'react';

type Props = {
  isLocked: boolean;
  ref?: RefObject<HTMLElement>;
};

export const useScrollLock = ({ isLocked, ref }: Props): void => {
  const overflowRef = useRef(``);

  useEffect(() => {
    const element = ref?.current || document.body;
    const overflow = element.style.overflow;
    const oldOverflow = overflowRef.current;
    overflowRef.current = overflow;

    element.style.overflow = isLocked ? `hidden` : oldOverflow;
  }, [isLocked]);
};
