import { useContext } from 'react';
import type { ReactNode } from 'react';

import { useClassName, useTab } from '../../hooks';

import { TabPanelContext } from './tab-panel-context';
import styles from './tab-panel.module.scss';

type Props = {
  children: ReactNode;
};

export const TabPanel = ({ children }: Props) => {
  const { tabId, panelId, isSelected } = useContext(TabPanelContext);

  const { controlProps } = useTab({
    isSelected,
    triggerId: tabId,
    controlId: panelId,
    controlClassName: styles.wrapper,
    controlSelectedClassName: useClassName([styles.wrapper, styles.selected]),
  });

  return <div {...controlProps}>{children}</div>;
};
