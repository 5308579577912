import { RefObject, useEffect, useRef } from 'react';
import type { ForwardedRef } from 'react';

import { isFunction } from '../../utils';

export function useForwardedRef<T>(
  forwardedRef: ForwardedRef<T>,
): RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (!forwardedRef) {
      return;
    }

    if (isFunction(forwardedRef)) {
      forwardedRef(ref.current);
    } else {
      forwardedRef.current = ref.current;
    }
  });

  return ref;
}
