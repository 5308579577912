import { IS_SERVER } from '../environment';

export const detectLanguage = (): string | undefined => {
  if (IS_SERVER) {
    return undefined;
  }

  const { language, languages } = navigator;
  const detected = languages?.[0] || language;
  return detected.substring(0, 2).toLowerCase();
};

export const extractLanguage = (locales: string[]) => {
  if (IS_SERVER) {
    return null;
  }

  //
  // 1) `/(l1|l2|l3|...)/xxx`
  // 2) `/(l1|l2|l3|...)/`
  // 3) `/(l1|l2|l3|...)`
  //
  const path = window.location.pathname;
  const group = `(${locales.join(`|`)})`;
  const regex = new RegExp(`\\/${group}(?=\\/|$)`);

  const match = path.match(regex);
  if (match === null) {
    return null;
  }

  return match[1];
};
