import { useId } from 'react';
import type { AriaAttributes, HTMLAttributes } from 'react';

type BaseProps = {
  hasControl?: boolean;
  controlId?: string;
  orientation?: AriaAttributes['aria-orientation'];
};

export type Props = BaseProps &
  (
    | {
        label?: AriaAttributes['aria-labelledby'];
        labelId?: never;
      }
    | {
        label?: never;
        labelId?: AriaAttributes['aria-labelledby'];
      }
  );

export type Result = {
  containerProps: HTMLAttributes<HTMLElement>;
  controlProps: HTMLAttributes<HTMLElement>;
};

export const useToolbar = ({
  hasControl = false,
  controlId = undefined,
  label = undefined,
  labelId = undefined,
  orientation = `horizontal`,
}: Props = {}): Result => {
  const defaultControlId = useId();
  const actualControlId = controlId || defaultControlId;

  return {
    containerProps: {
      role: `toolbar`,
      'aria-label': label,
      'aria-controls': hasControl ? actualControlId : undefined,
      'aria-labelledby': labelId,
      'aria-orientation': orientation,
    },
    controlProps: {
      id: actualControlId,
    },
  };
};
