import {
  useAccessibility,
  useMenu,
  usePagesByView,
  useTranslate,
} from '../../hooks';

import { NavItems } from './nav-items';

export const Nav = () => {
  const translate = useTranslate();
  const accessibility = useAccessibility();
  const pages = usePagesByView(`footer`);

  const menuProps = useMenu({
    orientation: `horizontal`,
    label: translate(accessibility?.data.menuFooterLabel),
  });

  return <NavItems {...menuProps} pages={pages} />;
};
