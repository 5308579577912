import { forwardRef, useEffect, useRef } from 'react';
import type { RefObject, ReactElement } from 'react';

import { useForwardedRef, useRovingIndex } from '../../hooks';

type Options = Parameters<ReturnType<typeof useRovingIndex>['item']>[1];

type Props = {
  children: (props: { ref: RefObject<any> }) => ReactElement;
  options?: Options;
};

export const RovingIndexItem = forwardRef<HTMLElement, Props>(
  function RovingIndexItem({ children, options = {} }: Props, ref) {
    const forwardedRef = useForwardedRef<HTMLElement>(ref);
    const optionsRef = useRef<Options>(options);
    optionsRef.current = options;

    const rovingIndex = useRovingIndex();

    useEffect(
      () => rovingIndex.item(forwardedRef, optionsRef.current),
      [rovingIndex],
    );

    return children({ ref: forwardedRef });
  },
);
