import { Locale } from '../../config';
import { useLocale } from '../contexts';

type Data<T extends object> =
  | Record<Locale, Queries.Maybe<T>>
  | null
  | undefined;
type TFunction = <T extends object>(data: Data<T>) => T | undefined;

export const useTranslateObject = (): TFunction => {
  const { locale } = useLocale();
  return <T extends object>(data: Data<T>) => data?.[locale] || undefined;
};
