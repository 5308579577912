import type { HTMLAttributes } from 'react';

import { useClassName } from '../../hooks';
import { isString } from '../../utils';

import styles from './inline-svg.module.scss';

type Props = {
  svg?: string | null;
  className?: string;
} & (
  | ({
      wrapper?: `div`;
    } & HTMLAttributes<HTMLDivElement>)
  | ({
      wrapper?: `span`;
    } & HTMLAttributes<HTMLSpanElement>)
);

export const InlineSVG = ({
  svg,
  className,
  wrapper: Wrapper = `span`,
}: Props) => {
  const wrapperClassName = useClassName([styles.wrapper, className]);

  return isString(svg) ? (
    <Wrapper
      aria-hidden={true}
      className={wrapperClassName}
      dangerouslySetInnerHTML={{
        __html: svg,
      }}
    />
  ) : null;
};
