import { Locale } from '../../config';
import { useLocale } from '../contexts';

type Data<T extends Record<string, unknown>> =
  | Record<Locale, Queries.Maybe<readonly T[]>>
  | null
  | undefined;
type TFunction = <T extends Record<string, unknown>>(
  data: Data<T>,
) => readonly T[];

export const useTranslateArray = (): TFunction => {
  const { locale } = useLocale();
  return <T extends Record<string, unknown>>(data: Data<T>) =>
    data?.[locale] || [];
};
