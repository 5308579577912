import { graphql } from 'gatsby';

import { Anchor, Markdown, PreWrap, Title, Trans } from '../components';
import { useTranslate, useTranslateObject } from '../hooks';
import { isValidPhone, toPhoneUri } from '../utils';

import { ContactForm } from './contact-form';
import styles from './contact.module.scss';

type Props = {
  pageContact: Queries.Squidex_PageContact | undefined;
  contact: Queries.Squidex_Contact | undefined;
  openingHours: Queries.Maybe<readonly Queries.Squidex_OpeningHours[]>;
};

export const Contact = ({ pageContact, openingHours, contact }: Props) => {
  const translate = useTranslate();
  const translateObject = useTranslateObject();

  const phone = translateObject(contact?.data.phone);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Markdown content={translate(pageContact?.data.content)} />
        <Title wrapper="h3">
          <Trans data={openingHours?.[0]?.data.title} />
        </Title>
        <PreWrap wrapper="p">
          <Trans data={openingHours?.[0]?.data.content} />
        </PreWrap>

        <address>
          <PreWrap wrapper="p">
            <Trans data={contact?.data.address} />
          </PreWrap>
        </address>

        <Title wrapper="h3">
          <Trans data={pageContact?.data.labelPhone} />
        </Title>
        {phone?.value && isValidPhone(phone?.value) ? (
          <div className={styles.contact}>
            <span>{phone.label}</span>
            <Anchor wrapper="a" href={toPhoneUri(phone?.value)}>
              {phone.value}
            </Anchor>
          </div>
        ) : null}
      </div>

      <div className={styles.form}>
        <Title wrapper="h3">
          <Trans data={pageContact?.data.labelForm} />
        </Title>
        <ContactForm pageContact={pageContact} />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment PageContact on Squidex {
    queryPageContactContents {
      data {
        title {
          de
          en
        }
        content {
          de
          en
        }
        labelPhone {
          de
          en
        }
        labelForm {
          de
          en
        }
        form {
          de {
            name
            label
            type
          }
          en {
            name
            label
            type
          }
        }
        submit {
          de
          en
        }
        messageLoading {
          de
          en
        }
        messageSuccess {
          de
          en
        }
        messageFailure {
          de
          en
        }
      }
      flatData {
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2560
                height: 1024
                layout: CONSTRAINED
                transformOptions: { cropFocus: ENTROPY }
              )
            }
          }
          ...SquidexAssetLocalFileProps
        }
      }
    }
  }

  fragment ContactOpeningHours on Squidex {
    queryOpeningHoursContents(filter: "data/view/iv eq 'homepage'") {
      data {
        title {
          de
          en
        }
        content {
          de
          en
        }
      }
    }
  }

  fragment ContactAddress on Squidex {
    queryContactContents {
      data {
        address {
          de
          en
        }
        phone {
          de {
            ...KeyValue
          }
          en {
            ...KeyValue
          }
        }
      }
    }
  }
`;
