import type { HTMLAttributes, ReactNode } from 'react';

import { useClassName } from '../../hooks';
import type { Breakpoint } from '../../types';

import styles from './container.module.scss';

export type Props = {
  children: ReactNode;
  maxSize?: Breakpoint;
  min?: Breakpoint;
  max?: Breakpoint;
  className?: string;
} & (
  | ({
      wrapper?: `div`;
    } & HTMLAttributes<HTMLDivElement>)
  | ({
      wrapper?: `section`;
    } & HTMLAttributes<HTMLElement>)
);

export const Container = ({
  children,
  maxSize = `lg`,
  min = undefined,
  max = undefined,
  className = undefined,
  wrapper: Wrapper = `div`,
  ...props
}: Props) => {
  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[maxSize],
    styles[`min-${min}`],
    styles[`max-${max}`],
    className,
  ]);

  return (
    <Wrapper {...props} className={wrapperClassName}>
      {children}
    </Wrapper>
  );
};
