import { useRef, useState, useEffect, useCallback } from 'react';

import { isBoolean, isObject } from '../../utils';

const STATE = {
  PRE_ENTER: `pre-enter`,
  ENTERING: `entering`,
  ENTERED: `entered`,
  PRE_EXIT: `pre-exit`,
  EXITING: `exiting`,
  EXITED: `exited`,
  UNMOUNTED: `unmounted`,
} as const;

type State = (typeof STATE)[keyof typeof STATE];
type Timeout = { enter: number; exit: number };

type Props = {
  timeout: Timeout | number;
  enter?: boolean;
  exit?: boolean;
  preEnter?: boolean;
  preExit?: boolean;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  initialEntered?: boolean;
  onChange?: (state: State) => void;
};

type ToggleFunction = (toEnter?: boolean) => void;
type EndTransitionFunction = () => void;

const startOrEnd = (unmounted: boolean) =>
  unmounted ? STATE.UNMOUNTED : STATE.EXITED;

const getTimeout = (timeout: Timeout | number): Timeout => ({
  exit: isObject(timeout) ? timeout.exit : timeout,
  enter: isObject(timeout) ? timeout.enter : timeout,
});

const isEnterStage = (state: State) => {
  switch (state) {
    case STATE.PRE_ENTER:
    case STATE.ENTERING:
    case STATE.ENTERED:
      return true;
    default:
      return false;
  }
};

export const useTransition = ({
  timeout,
  enter = true,
  exit = true,
  preEnter = false,
  preExit = false,
  mountOnEnter = false,
  unmountOnExit = false,
  initialEntered = false,
  onChange = undefined,
}: Props): [
  state: State,
  toggle: ToggleFunction,
  endTransition: EndTransitionFunction,
] => {
  const [state, setState] = useState<State>(
    initialEntered ? STATE.ENTERED : startOrEnd(mountOnEnter),
  );
  const timeoutRef = useRef<Timeout>(getTimeout(timeout));
  const latestStateRef = useRef<State>(state);
  const timerRef = useRef<NodeJS.Timeout>();

  const updateState = useCallback(
    (state: State) => {
      clearTimeout(timerRef.current);
      latestStateRef.current = state;
      setState(state);

      onChange?.(state);
    },
    [onChange],
  );

  const endTransition = useCallback(() => {
    let newState;
    switch (latestStateRef.current) {
      case STATE.ENTERING:
      case STATE.PRE_ENTER:
        newState = STATE.ENTERED;
        break;

      case STATE.EXITING:
      case STATE.PRE_EXIT:
        newState = startOrEnd(unmountOnExit);
        break;
      default:
    }

    if (newState) {
      updateState(newState);
    }
  }, [updateState, unmountOnExit]);

  const transitState = useCallback(
    (newState: State) => {
      const {
        current: { enter, exit },
      } = timeoutRef;

      updateState(newState);

      switch (newState) {
        case STATE.PRE_ENTER:
          timerRef.current = setTimeout(() => transitState(STATE.ENTERING), 0);
          break;

        case STATE.ENTERING:
          if (enter >= 0) {
            timerRef.current = setTimeout(endTransition, enter);
          }
          break;

        case STATE.PRE_EXIT:
          timerRef.current = setTimeout(() => transitState(STATE.EXITING), 0);
          break;

        case STATE.EXITING:
          if (exit >= 0) {
            timerRef.current = setTimeout(endTransition, exit);
          }
          break;

        default:
      }
    },
    [updateState, endTransition],
  );

  const toggle = useCallback(
    (toEnter?: boolean) => {
      const enterStage = isEnterStage(latestStateRef.current);
      toEnter = isBoolean(toEnter) ? toEnter : !enterStage;

      if (toEnter) {
        if (!enterStage) {
          transitState(
            enter
              ? preEnter
                ? STATE.PRE_ENTER
                : STATE.ENTERING
              : STATE.ENTERED,
          );
        }
      } else {
        if (enterStage) {
          transitState(
            exit
              ? preExit
                ? STATE.PRE_EXIT
                : STATE.EXITING
              : startOrEnd(unmountOnExit),
          );
        }
      }
    },
    [enter, exit, preEnter, preExit, transitState, unmountOnExit],
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(prefers-reduced-motion: reduce)`);
    const handler = () => {
      const { enter, exit } = getTimeout(timeout);
      timeoutRef.current.enter = mediaQuery.matches ? 0 : enter;
      timeoutRef.current.exit = mediaQuery.matches ? 0 : exit;
    };
    mediaQuery.addEventListener(`change`, handler);
    handler();

    return () => {
      mediaQuery.removeEventListener(`change`, handler);
    };
  }, [timeout]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return [state, toggle, endTransition];
};
