import { Children, isValidElement } from 'react';
import type { ComponentProps, ReactElement } from 'react';

import styles from './accordion-controlled.module.scss';
import { AccordionItem } from './accordion-item';
import { AccordionItemContext } from './accordion-item-context';


type AccordionItemProps = ComponentProps<typeof AccordionItem>;
type AccordionItemType = ReactElement<AccordionItemProps>;

type SelectedItems = Record<number, boolean | undefined>;

type Props = {
  children: AccordionItemType | AccordionItemType[];
  onToggle?: (
    selectedIndex: number | undefined,
    selectedItems: SelectedItems,
  ) => void;
  theme?: `dark` | `light`;
} & (
  | {
      autoClose?: false;
      selectedIndex?: never;
      selectedItems?: SelectedItems;
    }
  | {
      autoClose?: true;
      selectedIndex?: number;
      selectedItems?: never;
    }
);

export const AccordionControlled = ({
  children,
  selectedIndex = undefined,
  selectedItems = {},
  autoClose = false,
  onToggle = () => {},
  theme = `dark`,
}: Props) => {
  const isItemSelected = (index: number) =>
    autoClose ? selectedIndex === index : !!selectedItems[index];

  const toggleItem = (index: number) => {
    const nextSelectedIndex = index !== selectedIndex ? index : undefined;
    const nextSelectedItems = {
      ...selectedItems,
      [index]: !selectedItems[index],
    };

    onToggle(nextSelectedIndex, nextSelectedItems);
  };

  const items = Children.toArray(children).filter(
    (element: unknown): element is AccordionItemType =>
      isValidElement(element) && element.type === AccordionItem,
  );

  return (
    <div className={styles.wrapper}>
      {items.map((item, index) => (
        <AccordionItemContext.Provider
          key={index}
          value={{
            theme,
            toggle: () => toggleItem(index),
            isSelected: isItemSelected(index),
          }}
        >
          {item}
        </AccordionItemContext.Provider>
      ))}
    </div>
  );
};

AccordionControlled.Item = AccordionItem;
