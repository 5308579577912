import { InlineSVG, Text, Title, Trans } from '../components';
import {
  useLiveStatus,
  useLocale,
  useTranslate,
  useTranslateObject,
  useWeather,
  useFormatDate,
} from '../hooks';
import { isNumber } from '../utils';

import styles from './weather-forecast.module.scss';

const addDays = (date: Date, days = 1): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const format = (value: unknown, formatter: Intl.NumberFormat): string => {
  if (isNumber(value)) {
    return formatter.format(value);
  }

  return `-`;
};

export const WeatherForecast = () => {
  const translate = useTranslate();
  const translateObject = useTranslateObject();

  const weather = useWeather();
  const { locale } = useLocale();
  const liveStatus = useLiveStatus();

  const today = new Date();

  const date1 = useFormatDate({
    locale,
    date: addDays(today, 1),
    options: { weekday: `long` },
  });
  const date2 = useFormatDate({
    locale,
    date: addDays(today, 2),
    options: { weekday: `long` },
  });
  const date3 = useFormatDate({
    locale,
    date: addDays(today, 3),
    options: { weekday: `long` },
  });

  const day1 = translateObject(weather?.data.forecastDay1);
  const day2 = translateObject(weather?.data.forecastDay2);
  const day3 = translateObject(weather?.data.forecastDay3);

  const celsiusFormatter = new Intl.NumberFormat(locale, {
    style: `unit`,
    unit: `celsius`,
  });

  return (
    <div
      className={styles.wrapper}
      aria-label={translate(liveStatus?.data.weatherTitle)}
    >
      <div className={styles.header}>
        <Title wrapper="h3" className={styles.title}>
          <Trans data={liveStatus?.data.weatherForecast} />
        </Title>
      </div>

      <div className={styles.items}>
        <div className={styles.item}>
          <Title wrapper="h4" className={styles.title}>
            {date1}
          </Title>
          <div className={styles.symbol}>
            <InlineSVG
              wrapper="div"
              className={styles.icon}
              svg={day1?.code?.[0]?.flatData.icon?.[0].localFile?.svg?.content}
            />
          </div>
          <span
            className={styles.status}
            aria-label={translate(liveStatus?.data.weatherStatus)}
          >
            <Trans data={day1?.code?.[0]?.data.text} />
          </span>
          <Text
            size="fs-3"
            className={styles.temperature}
            aria-label={translate(liveStatus?.data.weatherTemperature)}
          >
            {format(day1?.temperatureAvg, celsiusFormatter)}
          </Text>
        </div>

        <div className={styles.item}>
          <Title wrapper="h4" className={styles.title}>
            {date2}
          </Title>
          <div className={styles.symbol}>
            <InlineSVG
              wrapper="div"
              className={styles.icon}
              svg={day2?.code?.[0]?.flatData.icon?.[0].localFile?.svg?.content}
            />
          </div>

          <span
            className={styles.status}
            aria-label={translate(liveStatus?.data.weatherStatus)}
          >
            <Trans data={day2?.code?.[0]?.data.text} />
          </span>
          <Text
            size="fs-3"
            className={styles.temperature}
            aria-label={translate(liveStatus?.data.weatherTemperature)}
          >
            {format(day2?.temperatureAvg, celsiusFormatter)}
          </Text>
        </div>

        <div className={styles.item}>
          <Title wrapper="h4" className={styles.title}>
            {date3}
          </Title>
          <div className={styles.symbol}>
            <InlineSVG
              wrapper="div"
              className={styles.icon}
              svg={day3?.code?.[0]?.flatData.icon?.[0].localFile?.svg?.content}
            />
          </div>
          <span
            className={styles.status}
            aria-label={translate(liveStatus?.data.weatherStatus)}
          >
            <Trans data={day3?.code?.[0]?.data.text} />
          </span>
          <Text
            size="fs-3"
            className={styles.temperature}
            aria-label={translate(liveStatus?.data.weatherTemperature)}
          >
            {format(day3?.temperatureAvg, celsiusFormatter)}
          </Text>
        </div>
      </div>
    </div>
  );
};
