import { createContext, useMemo, useEffect, useState, useRef } from 'react';
import type { RefObject, ReactNode } from 'react';

import { isElement } from '../../utils';

type Item = {
  element: HTMLElement;
  onFocusIn: (event: FocusEvent) => void;
  onKeyDown: (event: KeyboardEvent) => void;
  isFocusable: (element: HTMLElement) => boolean;
};

type Props<T extends HTMLElement = HTMLElement> = {
  children: ReactNode;
  parentRef: RefObject<T>;
  isEnabled?: boolean;
  directionLength?: number;
  direction?: 'vertical' | 'horizontal' | 'grid';
};

type Context = {
  item: (
    ref: RefObject<Item['element']>,
    options?: Partial<Omit<Item, 'element'>>,
  ) => () => void;
};

const noop = () => {};

const DEFAULT_CONTEXT: Context = {
  item: () => noop,
};

export function RovingIndexProvider<T extends HTMLElement>({
  parentRef,
  isEnabled = true,
  directionLength = 5,
  direction = `vertical`,
  ...props
}: Props<T>) {
  const [items, setItems] = useState<Item[]>([]);
  const isMountedRef = useRef<boolean>(false);

  const value = useMemo<Context>(
    () => ({
      item: (
        { current: element },
        { onKeyDown = noop, onFocusIn = noop, isFocusable = () => true } = {},
      ) => {
        if (!isElement(element)) {
          console.error(`item() requires ref holding HTMLElement!`);
          return noop;
        }

        setItems((items) => [
          ...items,
          { element, onKeyDown, onFocusIn, isFocusable },
        ]);

        return () => {
          setItems((items) =>
            items.filter((current) => current.element !== element),
          );
        };
      },
    }),
    [],
  );

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }

    const computeNextIndex = (delta: number): number => {
      const getNextIndex = (start: number) => (start + length + delta) % length;
      const length = items.length;

      let nextIndex = getNextIndex(currentIndex);
      let steps = length;
      while (
        steps &&
        items[nextIndex] &&
        !items[nextIndex].isFocusable(items[nextIndex].element)
      ) {
        nextIndex = getNextIndex(nextIndex);
        steps -= 1;
      }

      return nextIndex;
    };

    const keyDownHandler = (event: KeyboardEvent) => {
      items[currentIndex]?.onKeyDown(event);
      if (event.defaultPrevented) {
        return;
      }

      const maxIndex = items.length - 1;

      let delta = 0;
      switch (event.code) {
        case `ArrowRight`:
          delta += direction === `horizontal` ? 1 : 0;
          break;
        case `ArrowDown`:
          delta +=
            direction === `grid`
              ? directionLength
              : direction === `vertical`
              ? 1
              : 0;
          break;
        case `ArrowLeft`:
          delta -= direction === `horizontal` ? 1 : 0;
          break;
        case `ArrowUp`:
          delta -=
            direction === `grid`
              ? directionLength
              : direction === `vertical`
              ? 1
              : 0;
          break;
        case `End`:
          currentIndex = 0;
          delta -= 1;
          break;
        case `Home`:
          currentIndex = maxIndex;
          delta += 1;
          break;
        case `Tab`:
          currentIndex = 0;
          break;
        default:
      }

      if (delta === 0) {
        updateIndex();
        return;
      }

      event.preventDefault();

      if (direction === `grid` && currentIndex + delta < 0) {
        currentIndex = 0;
      } else if (direction === `grid` && currentIndex + delta > maxIndex) {
        currentIndex = maxIndex;
      } else {
        currentIndex = computeNextIndex(delta);
      }

      updateFocus();
    };

    const focusInHandler = (event: FocusEvent) => {
      items[currentIndex]?.onFocusIn(event);
      if (event.defaultPrevented) {
        return;
      }

      event.preventDefault();

      const targetIndex = items.findIndex(
        ({ element }) => element === event.target,
      );
      currentIndex = targetIndex < 0 ? currentIndex : targetIndex;

      updateIndex();
    };

    const updateIndex = () => {
      items.forEach(({ element }, index) => {
        element.tabIndex = currentIndex === index ? 0 : -1;
      });
    };

    const updateFocus = () => {
      let focusItem = items[currentIndex];

      if (!focusItem || !focusItem.isFocusable(focusItem.element)) {
        currentIndex = computeNextIndex(1);
        focusItem = items[currentIndex];
      }

      focusItem?.element.focus();
    };

    // if not enabled all items get `tabIndex=-1`
    let currentIndex = isEnabled ? 0 : -1;
    updateIndex();

    parentRef.current?.addEventListener(`keydown`, keyDownHandler);
    parentRef.current?.addEventListener(`focusin`, focusInHandler);

    return () => {
      parentRef.current?.removeEventListener(`keydown`, keyDownHandler);
      parentRef.current?.removeEventListener(`focusin`, focusInHandler);
    };
  }, [items, isEnabled, direction, directionLength]);

  return <RovingIndexContext.Provider value={value} {...props} />;
}

export const RovingIndexContext = createContext<Context>(DEFAULT_CONTEXT);
