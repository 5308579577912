import { useContext } from 'react';
import type { ReactNode } from 'react';

import { useClassName, useTab } from '../../hooks';
import { RovingIndexItem } from '../a11y';

import { TabContext } from './tab-context';
import styles from './tab.module.scss';

type Props = {
  children: ReactNode;
};

export const Tab = ({ children }: Props) => {
  const { tabId, panelId, isSelected, select } = useContext(TabContext);
  const wrapperClassName = useClassName([
    styles.wrapper,
    isSelected ? styles.selected : ``,
  ]);

  const { triggerProps } = useTab({
    isSelected,
    onSelect: select,
    triggerId: tabId,
    controlId: panelId,
    controlClassName: styles.panel,
    controlSelectedClassName: styles.selected,
  });

  return (
    <RovingIndexItem options={{ onFocusIn: () => select() }}>
      {(rovingItem) => (
        <button {...rovingItem} {...triggerProps} className={wrapperClassName}>
          {children}
        </button>
      )}
    </RovingIndexItem>
  );
};
