import type { HTMLAttributes } from 'react';

import { RovingIndexContainer } from '../../components/';
import { useClassName } from '../../hooks';

import { NavItem } from './nav-item';
import styles from './nav-items.module.scss';

export type Props = HTMLAttributes<HTMLUListElement> & {
  pages: readonly Queries.Squidex_Pages[];
};

export const NavItems = ({ pages, className, ...restProps }: Props) => {
  const wrapperClassName = useClassName([styles.wrapper, className]);

  return (
    <RovingIndexContainer direction="horizontal">
      {(rovingContainer) => (
        <ul {...restProps} {...rovingContainer} className={wrapperClassName}>
          {pages.map((page) => (
            <li role="none" className={styles.item} key={page.flatData.alias}>
              <NavItem page={page} />
            </li>
          ))}
        </ul>
      )}
    </RovingIndexContainer>
  );
};
