import { Fragment, useState, useRef, useEffect } from 'react';

import { RovingIndexItem, Text } from '../../components';
import {
  useClassName,
  // useClickOutside,
  useSubmenu,
  useTranslate,
} from '../../hooks';
import { MinusIcon, PlusIcon } from '../../icons';

import { NavItems } from './nav-items';
import type { Props as NavItemsProps } from './nav-items';
import styles from './nav-submenu.module.scss';

type Props = Omit<NavItemsProps, 'isEnabled'> & {
  parent: Queries.Squidex_Pages;
};

export const NavSubmenu = ({
  parent,
  pages,
  level,
  className,
  ...navItemProps
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isExpandedRef = useRef<boolean>(isExpanded);
  const navItemsRef = useRef<HTMLUListElement>(null);
  const rovingIndexRef = useRef<HTMLButtonElement>(null);
  const buttonClassName = useClassName([className, styles.button]);

  const translate = useTranslate();

  const label = translate(parent.data.title);
  const { triggerProps, controlProps } = useSubmenu({
    label,
    isExpanded,
    onToggle: setIsExpanded,
    controlClassName: styles.items,
    controlExpandedClassName: useClassName([styles.items, styles.active]),
  });

  useEffect(() => {
    isExpandedRef.current = isExpanded;
  });

  // useClickOutside(navItemsRef, (target) => {
  //   if (
  //     !rovingIndexRef.current?.contains(target) &&
  //     !rovingIndexRef.current?.isEqualNode(target)
  //   ) {
  //     setIsExpanded(false);
  //   }
  // });

  return (
    <Fragment>
      <RovingIndexItem
        ref={rovingIndexRef}
        options={{
          onKeyDown: (event) => {
            const { current: isExpanded } = isExpandedRef;

            switch (event.code) {
              case `Escape`:
              case `ArrowLeft`:
                if (isExpanded) {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsExpanded(false);
                  rovingIndexRef.current?.focus();
                }
                break;
              case `Enter`:
              case `ArrowRight`:
                if (!isExpanded) {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsExpanded(true);
                }
                break;
              case `Tab`:
                if (isExpanded) {
                  // close when navigating away
                  setIsExpanded(false);
                }
                break;
              default:
            }
          },
        }}
      >
        {(rovingItem) => (
          <button {...triggerProps} {...rovingItem} className={buttonClassName}>
            <Text size="fs-3" wrapper="span">
              {label}
            </Text>
            {isExpanded ? (
              <MinusIcon className={styles.icon} aria-hidden="true" />
            ) : (
              <PlusIcon className={styles.icon} aria-hidden="true" />
            )}
          </button>
        )}
      </RovingIndexItem>
      <NavItems
        {...controlProps}
        {...navItemProps}
        pages={pages}
        level={level}
        isEnabled={isExpanded}
        ref={navItemsRef}
      />
    </Fragment>
  );
};
