import { Link } from 'gatsby';

import {
  Languages,
  RovingIndexContainer,
  RovingIndexItem,
  Text,
} from '../../components';

import styles from './language-switcher.module.scss';

type Props = {
  isEnabled: boolean;
};

export const LanguageSwitcher = ({ isEnabled }: Props) => (
  <RovingIndexContainer isEnabled={isEnabled} direction="horizontal">
    {(rovingContainer) => (
      <div className={styles.wrapper} {...rovingContainer}>
        <Languages>
          {({ href: to, isActive, ...language }) => (
            <RovingIndexItem key={to}>
              {(rovingItem) => (
                <Link
                  {...language}
                  {...rovingItem}
                  to={to}
                  partiallyActive={true}
                  className={`${styles.item} ${isActive ? styles.active : ``}`}
                >
                  <Text size="fs-2" wrapper="span">
                    {language.lang}
                  </Text>
                </Link>
              )}
            </RovingIndexItem>
          )}
        </Languages>
      </div>
    )}
  </RovingIndexContainer>
);
