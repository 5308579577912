import { useId } from 'react';
import type { AriaAttributes, ReactNode } from 'react';

import { Tab } from './tab';
import { TabList } from './tab-list';
import { TabPanel } from './tab-panel';
import { TabPanels } from './tab-panels';
import { TabsContext } from './tabs-context';

type Props = {
  children: ReactNode;
  selectedIndex: number;
  onSelect: (index: number) => void;
  direction?: AriaAttributes['aria-orientation'];
};

export const TabsControlled = ({
  children,
  selectedIndex = 0,
  direction = `horizontal`,
  onSelect = () => {},
}: Props) => {
  const id = useId();

  const makeTabId = (index: number) => `${id}tab-${index}`;
  const makePanelId = (index: number) => `${id}panel-${index}`;
  const isTabSelected = (index: number) => selectedIndex === index;

  const selectTab = (index: number) => {
    onSelect?.(index);
  };

  return (
    <TabsContext.Provider
      value={{
        direction,
        selectTab,
        isTabSelected,
        makeTabId,
        makePanelId,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

TabsControlled.TabList = TabList;
TabsControlled.Tab = Tab;

TabsControlled.TabPanels = TabPanels;
TabsControlled.TabPanel = TabPanel;
