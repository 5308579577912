import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

type Props<T extends HTMLElement> = {
  ref: RefObject<T>;
};

type Result = {
  isFullscreen: boolean;
  supportsFullscreen: boolean;
  toggleFullscreen: () => void;
};

export function useFullscreen<T extends HTMLElement>({
  ref,
}: Props<T>): Result {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [supportsFullscreen, setSupportsFullscreen] = useState<boolean>(false);

  const toggleFullscreen = async () => {
    const element = ref.current;
    if (!element) {
      return;
    }

    try {
      if (!document.fullscreenElement) {
        await element.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(
          `Error attempting to enable fullscreen mode: ${error.message} (${error.name})`,
        );
      }
    }
  };

  useEffect(() => {
    const supportsFullscreen = document.fullscreenEnabled;
    setSupportsFullscreen(supportsFullscreen);
    if (!supportsFullscreen) {
      return;
    }

    const changeHandler = () => setIsFullscreen(!!document.fullscreenElement);

    document.addEventListener(`fullscreenchange`, changeHandler);
    return () =>
      document.removeEventListener(`fullscreenchange`, changeHandler);
  }, []);

  return { isFullscreen, supportsFullscreen, toggleFullscreen };
}
