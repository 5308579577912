import { useDisclosure } from './use-disclosure';
import type { Props as DisclosureProps, Result } from './use-disclosure';

type Props = Omit<
  DisclosureProps,
  | 'onToggle'
  | 'isExpanded'
  | 'controlExpandedClassName'
  | 'controlBindLabelToButton'
> & {
  onSelect?: DisclosureProps['onToggle'];
  isSelected: DisclosureProps['isExpanded'];
  controlSelectedClassName?: DisclosureProps['controlExpandedClassName'];
};

export const useTab = ({
  onSelect: onToggle = () => {},
  isSelected: isExpanded,
  controlSelectedClassName: controlExpandedClassName,
  ...props
}: Props): Result => {
  const {
    controlProps: baseControlProps,
    triggerProps: { 'aria-expanded': ariaExpanded, ...restTriggerProps },
  } = useDisclosure({
    ...props,
    onToggle,
    isExpanded,
    controlExpandedClassName,
    controlBindLabelToButton: true,
  });

  const triggerProps = {
    ...restTriggerProps,
    role: `tab`,
    'aria-expanded': undefined,
    'aria-haspopup': undefined,
    'aria-selected': ariaExpanded,
  };

  const controlProps = {
    ...baseControlProps,
    role: `tabpanel`,
  };

  return { triggerProps, controlProps };
};
