import { useLiveStatus, useTranslate } from '../hooks';

import { WebcamPreview } from './webcam-preview';
import styles from './webcams-preview.module.scss';

type Props = {
  webcams: readonly Queries.Squidex_Webcams[];
};

export const WebcamsPreview = ({ webcams }: Props) => {
  const translate = useTranslate();
  const liveStatus = useLiveStatus();

  return (
    <div
      className={styles.wrapper}
      aria-label={translate(liveStatus?.data.webcamsTitle)}
    >
      {webcams.map((webcam) => (
        <WebcamPreview key={webcam.id} webcam={webcam} />
      ))}
    </div>
  );
};
