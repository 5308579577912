import { useLiveStatus, useTranslate } from '../hooks';

import { Slope } from './slope';
import styles from './slopes.module.scss';

type Props = {
  slopes: readonly Queries.Squidex_Slopes[];
  statusVisible?: boolean;
};

export const Slopes = ({ slopes, statusVisible }: Props) => {
  const translate = useTranslate();
  const liveStatus = useLiveStatus();

  return slopes.length > 0 ? (
    <div
      className={styles.wrapper}
      aria-label={translate(liveStatus?.data.facilitiesTitle)}
    >
      {slopes?.map((slope) => (
        <Slope key={slope.id} slope={slope} statusVisible={statusVisible} />
      ))}
    </div>
  ) : null;
};
