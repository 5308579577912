import { useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';

import {
  useAccessibility,
  useButton,
  useClassName,
  useNotification,
  useTranslate,
} from '../hooks';
import { InfoIcon, CloseIcon, WarningIcon } from '../icons';
import { hashCode, isString } from '../utils';

import styles from './notification.module.scss';

const STORAGE_KEY = `___PK_ALERT_READ___`;

const getIsVisible = (value: unknown) => {
  if (!isString(value)) {
    return false;
  }

  const visibleUntil = new Date(value);
  if (!(visibleUntil instanceof Date)) {
    return false;
  }

  if (isNaN(visibleUntil.getTime())) {
    return false;
  }

  return visibleUntil.getTime() > new Date().getTime();
};

const getIconFromType = (
  type?: Queries.Squidex_NotificationDataTypeEnum | null,
): ReactNode => {
  switch (type) {
    case `negative`:
      return <WarningIcon className={styles.icon} />;
    case `positive`:
      return <InfoIcon className={styles.icon} />;
    default:
      return undefined;
  }
};

type Props = {
  className?: string;
};

export const Notification = ({ className }: Props) => {
  const translate = useTranslate();
  const notification = useNotification();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(() =>
    getIsVisible(notification?.flatData.visibleUntil),
  );

  const wrapperClassName = useClassName([
    styles.wrapper,
    !isChecked ? styles.hide : undefined,
    notification?.flatData.type
      ? styles[notification?.flatData.type]
      : undefined,
    className,
  ]);
  const accessibility = useAccessibility();
  const button = useButton({
    label: translate(accessibility?.data.hideMessage),
  });

  const message = translate(notification?.data.text);
  const visibleUntil = notification?.flatData.visibleUntil;
  const hashedValue = useMemo(() => `${hashCode(message)}`, [message]);

  const closeHandler = () => {
    localStorage.setItem(STORAGE_KEY, hashedValue);
    setIsVisible(false);
  };

  useEffect(() => {
    setIsVisible(() => {
      // a) hide message if passed `visibleUntil` end date from backend
      const isVisible = getIsVisible(visibleUntil);
      if (!isVisible) {
        return false;
      }

      // b) if visible, check if THIS message was hidden already by user
      const storedValue = localStorage.getItem(STORAGE_KEY);
      return storedValue !== hashedValue;
    });

    setIsChecked(true);
  }, [visibleUntil, hashedValue]);

  return isVisible ? (
    <div className={wrapperClassName} role="alert">
      <div className={styles.content}>
        <div className={styles.symbol}>
          {getIconFromType(notification?.flatData.type)}
        </div>
        {message}
      </div>
      <button {...button} className={styles.close} onClick={closeHandler}>
        <CloseIcon className={styles.icon} />
      </button>
    </div>
  ) : null;
};
