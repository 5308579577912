import { useDisclosure } from './use-disclosure';
import type {
  Props as DisclosureProps,
  Result as DisclosureResult,
} from './use-disclosure';
import { useMenu } from './use-menu';
import type { Props as MenuProps } from './use-menu';
import { useMenuItem } from './use-menuitem';
import type { Props as MenuItemProps } from './use-menuitem';

type Props = Pick<
  DisclosureProps,
  'isExpanded' | 'onToggle' | 'controlClassName' | 'controlExpandedClassName'
> &
  Pick<MenuItemProps, 'isDisabled'> &
  Required<Pick<MenuProps, 'label'>>;

type Result = DisclosureResult;

export const useSubmenu = ({
  label,
  isDisabled = undefined,
  ...restDisclosureProps
}: Props): Result => {
  const menuItemProps = useMenuItem({ type: `menuitem`, isDisabled });
  const { triggerProps, controlProps } = useDisclosure({
    ...restDisclosureProps,
    type: `menu`,
  });
  const menuProps = useMenu({ label });

  return {
    triggerProps: {
      ...triggerProps,
      ...menuItemProps,
    },
    controlProps: {
      ...controlProps,
      ...menuProps,
    },
  };
};
