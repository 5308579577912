import { useEffect } from 'react';
import type { RefObject } from 'react';

export function useFocusFirst<T extends HTMLElement>(
  ref: RefObject<T>,
  active: boolean,
): void {
  useEffect(() => {
    if (active) {
      const element = ref.current?.querySelector<HTMLElement>(
        `[tabIndex]:not([tabIndex="-1"])`,
      );

      element?.focus();
    }
  }, [active]);
}
