import { forwardRef } from 'react';
import type { ReactElement, ComponentProps, RefObject } from 'react';

import { RovingIndexProvider } from '../../contexts';
import { useForwardedRef } from '../../hooks';

type ProviderProps = Omit<
  ComponentProps<typeof RovingIndexProvider>,
  'parentRef' | 'children'
>;

type Props = {
  children: (props: { ref: RefObject<any> }) => ReactElement;
} & ProviderProps;

export const RovingIndexContainer = forwardRef<HTMLElement, Props>(
  function RovingIndexContainer({ children, ...props }: Props, ref) {
    const forwardedRef = useForwardedRef(ref);

    return (
      <RovingIndexProvider parentRef={forwardedRef} {...props}>
        {children({ ref: forwardedRef })}
      </RovingIndexProvider>
    );
  },
);
