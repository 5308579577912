import { Fragment } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';

import { useClassName } from '../../hooks';
import { isNumber, isString, isUndefined, truncate } from '../../utils';

import styles from './title.module.scss';

export type Props = {
  children: ReactNode;
  color?: `black` | `white` | `grey` | `primary` | `secondary`;
  family?: `ff-1`;
  size?: `fs-1` | `fs-2` | `fs-3` | `fs-4` | `fs-5` | `fs-6`;
  weight?: `fw-500` | `fw-700`;
  transform?: `uppercase` | `lowercase`;
  maxLength?: number;
  className?: string;
  ellipsis?: boolean;
  wrapper?: `h1` | `h2` | `h3` | `h4` | `h5` | `h6`;
} & HTMLAttributes<HTMLHeadingElement>;

export const Title = ({
  children,
  wrapper: Wrapper = `h2`,
  color = undefined,
  family = undefined,
  size = undefined,
  weight = undefined,
  transform = undefined,
  className = undefined,
  maxLength = undefined,
  ellipsis = undefined,
  ...domProps
}: Props) => {
  size = isUndefined(size)
    ? Wrapper === `h1`
      ? `fs-5`
      : Wrapper === `h2`
      ? `fs-4`
      : Wrapper === `h3`
      ? `fs-3`
      : Wrapper === `h4`
      ? `fs-2`
      : `fs-1`
    : size;

  const content =
    isString(children) && isNumber(maxLength)
      ? truncate(children, maxLength)
      : children;

  const wrapperClassName = useClassName([
    styles.wrapper,
    color ? styles[color] : ``,
    family ? styles[family] : ``,
    size ? styles[size] : ``,
    weight ? styles[weight] : ``,
    transform ? styles[transform] : ``,
    ellipsis ? styles.ellipsis : ``,
    className,
  ]);

  const innerWrapper = ellipsis ? (
    <span className={styles.inner} />
  ) : (
    <Fragment />
  );

  return (
    <Wrapper {...domProps} className={wrapperClassName}>
      <innerWrapper.type {...innerWrapper.props}>{content}</innerWrapper.type>
    </Wrapper>
  );
};
