import { useId } from 'react';

import { InlineSVG, SROnly } from '../components';
import { useClassName } from '../hooks';

import styles from './directions-option.module.scss';

type Props = {
  isChecked: boolean;
  option: Queries.Squidex_DirectionsDataOptionsChildDto;
  onToggle: (
    option: Queries.Squidex_DirectionsDataOptionsChildDto,
    isPressed: boolean,
  ) => void;
};

export const DirectionsOption = ({ option, isChecked, onToggle }: Props) => {
  const id = useId();

  const wrapperClassName = useClassName([
    styles.wrapper,
    isChecked ? styles.active : ``,
  ]);

  return (
    <label htmlFor={id} className={wrapperClassName}>
      <SROnly>
        <input
          id={id}
          type="radio"
          name="travelmode"
          checked={isChecked}
          className={styles.input}
          value={option.travelmode || undefined}
          onChange={({ target: { checked } }) => onToggle(option, checked)}
        />
        {option.name}
      </SROnly>

      <InlineSVG
        className={styles.icon}
        svg={option.icon?.[0]?.localFile?.svg?.content}
      />
    </label>
  );
};
