import { graphql } from 'gatsby';
import { Fragment } from 'react';

import { Accordion, PreWrap, Text, Title, Trans } from '../components';
import { useTranslateArray } from '../hooks';
import { stringify } from '../utils';

import styles from './prices.module.scss';

type Props = {
  prices: Queries.Squidex_Prices | undefined;
};

export const Prices = ({ prices }: Props) => {
  const translateArray = useTranslateArray();

  return (
    <Fragment>
      <Title>
        <Trans data={prices?.data.title} />
      </Title>
      <PreWrap>
        <Trans data={prices?.data.content} />
      </PreWrap>

      <Accordion autoClose={true}>
        {translateArray(prices?.data.categories).map(
          ({ title, items }, categoryIndex) => (
            <Accordion.Item key={categoryIndex} label={stringify(title)}>
              {items?.map(({ title, info, price }, itemIndex) => (
                <div
                  key={`${categoryIndex}-${itemIndex}`}
                  className={styles.ticket}
                >
                  <div className={styles.text}>
                    <Title
                      wrapper="h4"
                      size="fs-3"
                      weight="fw-700"
                      className={styles.title}
                    >
                      {title}
                    </Title>
                    {info}
                  </div>
                  <Text size="fs-3" className={styles.price}>
                    {price}
                  </Text>
                </div>
              ))}
            </Accordion.Item>
          ),
        )}
      </Accordion>
    </Fragment>
  );
};

export const query = graphql`
  fragment Prices on Squidex {
    queryPricesContents {
      id
      data {
        title {
          de
          en
        }
        content {
          de
          en
        }
        categories {
          de {
            ...PriceCategories
          }
          en {
            ...PriceCategories
          }
        }
        additionalInformation {
          de
          en
        }
      }
      flatData {
        season {
          flatData {
            alias
          }
        }
      }
    }
  }

  fragment PriceCategories on Squidex_PriceCategoriesComponent {
    title
    items {
      title
      info
      price
    }
  }
`;
