import {
  Children,
  isValidElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { ComponentProps, ReactElement } from 'react';

import { CarouselContext } from './carousel-context';
import { CarouselItem } from './carousel-item';
import { CarouselItemContext } from './carousel-item-context';
import styles from './carousel-items.module.scss';

type CarouselItemProps = ComponentProps<typeof CarouselItem>;
type CarouselItemType = ReactElement<CarouselItemProps>;

type Props = {
  children: CarouselItemType[];
};

export const CarouselItems = ({ children }: Props) => {
  const {
    itemsId,
    itemCount,
    autoHeight,
    selectionState,
    makeItemId,
    selectItem,
    resolveItem,
    isItemSelected,
  } = useContext(CarouselContext);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [resolvedIndex, setResolvedIndex] = useState<number>(0);

  const items = Children.toArray(children).filter(
    (element: unknown): element is CarouselItemType =>
      isValidElement(element) && element.type === CarouselItem,
  );

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper || !autoHeight) {
      return;
    }

    const updateHeight = () => {
      const items = [...Array.from(wrapper.childNodes)] as HTMLElement[];
      items[resolvedIndex].style.height = `auto`;
      items
        .filter((item) => !item.isSameNode(items[resolvedIndex]))
        .forEach((current) => {
          current.style.height = `${items[resolvedIndex].clientHeight}px`;
        });
    };

    const timeoutId = setTimeout(updateHeight, 50);
    window.addEventListener(`resize`, updateHeight);

    return () => {
      window.removeEventListener(`resize`, updateHeight);
      clearTimeout(timeoutId);
    };
  }, [autoHeight, resolvedIndex]);

  return (
    <div
      id={itemsId}
      aria-live="polite"
      className={styles.wrapper}
      ref={wrapperRef}
    >
      {items.map((item, index) => (
        <CarouselItemContext.Provider
          key={makeItemId(index)}
          value={{
            selectionState,
            itemId: makeItemId(index),
            select: () => selectItem(index),
            resolve: () => {
              setResolvedIndex(index);
              resolveItem(index);
            },
            isSelected: isItemSelected(index),
            label: `${index + 1} / ${itemCount}`,
          }}
        >
          {item}
        </CarouselItemContext.Provider>
      ))}
    </div>
  );
};
