import { forwardRef } from 'react';
import type { HTMLAttributes } from 'react';

import { Text, Trans } from '../../components';
import { useClassName, useLiveStatus } from '../../hooks';

import styles from './live-toggle.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & {
  isExpanded: boolean;
};

export const LiveToggle = forwardRef<HTMLButtonElement, Props>(
  ({ isExpanded, ...domProps }: Props, ref) => {
    const liveStatus = useLiveStatus();
    const wrapperClassName = useClassName([
      styles.wrapper,
      isExpanded ? styles.expanded : ``,
    ]);

    return (
      <button {...domProps} className={wrapperClassName} ref={ref}>
        <span className={styles.holder}>
          <Text wrapper="span" size="fs-1">
            <Trans data={liveStatus?.data.label} />
          </Text>
        </span>
      </button>
    );
  },
);

LiveToggle.displayName = `LiveToggle`;
