import { graphql, useStaticQuery } from 'gatsby';

export const useNotification = (): Queries.Squidex_Notification | undefined => {
  const data = useStaticQuery<Queries.Query>(query);

  return data.squidex.queryNotificationContents?.[0];
};

const query = graphql`
  query useNotificationQuery {
    squidex {
      queryNotificationContents {
        data {
          text {
            de
            en
          }
        }
        flatData {
          type
          visibleUntil
        }
      }
    }
  }
`;
