import { useId } from 'react';

import { useClassName } from '../../../hooks';
import { Markdown } from '../../html';
import type { InputProps } from '../types';

import styles from './checkbox.module.scss';
import { Input } from './input';


export type Props = InputProps<boolean> & {
  label?: string;
};

export const CheckboxField = ({
  value,
  label = undefined,
  className = undefined,
  onChange,
  ...props
}: Props) => {
  const autoId = useId();
  const id = props.id || autoId;
  const inputClassName = useClassName([styles.input, className]);

  return (
    <label htmlFor={id} className={styles.wrapper}>
      <Input
        {...props}
        id={id}
        type="checkbox"
        checked={value}
        className={inputClassName}
        onChange={(event) => onChange?.(event.target.checked)}
      />
      <div className={styles.checkmark} />
      {label && <Markdown isInline={true} content={label} />}
    </label>
  );
};
