import type { InputProps } from '../types';

import { Input } from './input';

export type Props = InputProps<number>;

export const DateField = ({ value, onChange, ...props }: Props) => (
  <Input
    {...props}
    value={value}
    type={value ? `date` : `text`}
    onFocus={(event) => {
      event.target.type = `date`;
    }}
    onBlur={(event) => {
      event.target.type = event.target.value ? `date` : `text`;
    }}
    onChange={(event) => onChange?.(event.target.valueAsNumber)}
  />
);
