module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T58M5T5","includeInDevelopment":false,"routeChangeEventName":"gatsby-page-view","enableWebVitalsTracking":false,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"de","start_url":"/","display":"standalone","name":"Patscherkofel Website","short_name":"Patscherkofel Website","description":"Alle Neuigkeiten und Events am Patscherkofel.","theme_color":"#303030","background_color":"#FFFFFF","icon":"src/svg/favicon.svg","crossOrigin":"use-credentials","localize":[{"lang":"en","start_url":"/en/","name":"Patscherkofel Website","short_name":"Patscherkofel Website","description":"All news and events at Patscherkofel."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"49b4ecca72306c6dcdfd057a7eafc731"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
