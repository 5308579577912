import L from 'leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  useAccessibility,
  useButton,
  useClassName,
  useFullscreen,
  useTranslate,
} from '../hooks';
import {
  MinusIcon,
  PlusIcon,
  OpenFullscreenIcon,
  CloseFullscreenIcon,
} from '../icons';
import { isArray } from '../utils';

import styles from './panorama.module.scss';

type Props = {
  svg: Queries.Squidex_Asset | undefined;
  className?: string;
};

export const Panorama = ({ svg, className }: Props) => {
  const translate = useTranslate();
  const accessibility = useAccessibility();

  const wrapperClassName = useClassName([styles.wrapper, className]);

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<L.Map | null>(null);
  const [zoomStatus, setZoomStatus] = useState<{
    zoomIn: boolean;
    zoomOut: boolean;
  }>({ zoomIn: false, zoomOut: false });
  const [mapSize, setMapSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const { isFullscreen, supportsFullscreen, toggleFullscreen } = useFullscreen({
    ref,
  });

  const svgURL = svg?.localFile?.publicURL;
  const svgDataUri = svg?.localFile?.svg?.dataURI;
  const { svgWidth, svgHeight } = useMemo(() => {
    const unmatched = { svgWidth: 0, svgHeight: 0, svgAspectRatio: [0, 0] };
    const match = svgDataUri?.match(/viewBox=(?:'|")([^'"]+)(?:'|")/);
    if (!isArray(match)) {
      return unmatched;
    }

    const parts = match[1].split(` `);
    if (parts.length !== 4) {
      return unmatched;
    }

    const svgWidth = parseFloat(parts[2]);
    if (isNaN(svgWidth)) {
      return unmatched;
    }

    const svgHeight = parseFloat(parts[3]);
    if (isNaN(svgHeight)) {
      return unmatched;
    }

    return {
      svgWidth,
      svgHeight,
    };
  }, [svgDataUri]);

  const zoomInHandler = () => {
    if (!map) {
      return;
    }

    const { zoomDelta = 1 } = map.options;
    map.setZoom(map.getZoom() + zoomDelta);
  };
  const zoomOutHandler = () => {
    if (!map) {
      return;
    }

    const { zoomDelta = 1 } = map.options;
    map.setZoom(map.getZoom() - zoomDelta);
  };

  const fullscreenToggleButton = useButton({
    isPressed: isFullscreen,
    onToggle: toggleFullscreen,
    isDisabled: !supportsFullscreen,
    label: isFullscreen
      ? translate(accessibility?.data.closeFullscreen)
      : translate(accessibility?.data.openFullscreen),
  });

  const zoomInButton = useButton({
    onClick: zoomInHandler,
    isDisabled: !zoomStatus.zoomIn,
    label: translate(accessibility?.data.enlarge),
  });

  const zoomOutButton = useButton({
    onClick: zoomOutHandler,
    isDisabled: !zoomStatus.zoomOut,
    label: translate(accessibility?.data.shrink),
  });

  const controlsLeftClassName = useClassName([styles.controls, styles.left]);
  const controlsRightClassName = useClassName([styles.controls, styles.right]);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const computeMapSize = () => {
      const { width, height } = element.getBoundingClientRect();
      const aspectRatio = svgWidth / svgHeight;

      setMapSize({
        width: height * aspectRatio,
        height: width / aspectRatio,
      });
    };

    computeMapSize();
    window.addEventListener(`resize`, computeMapSize);
    return () => {
      window.removeEventListener(`resize`, computeMapSize);
    };
  }, [svgWidth, svgHeight]);

  useEffect(() => {
    const element = ref.current;
    if (!element || !svgURL) {
      return;
    }

    const pointToLatLng = (x: number, y: number): [number, number] => {
      const zoom = Math.ceil(
        Math.log2(Math.max(mapSize.width, mapSize.height)),
      );
      const { lat, lng } = L.CRS.Simple.pointToLatLng(new L.Point(x, y), zoom);

      return [lat, lng];
    };
    const bounds = [pointToLatLng(0, 0), pointToLatLng(svgWidth, svgHeight)];

    const map = L.map(element, {
      zoomSnap: 0,
      zoomDelta: 0.25,
      crs: L.CRS.Simple,
      maxBounds: bounds,
      maxBoundsViscosity: 1,
      touchZoom: `center`,
      doubleClickZoom: `center`,
      zoomControl: false,
      scrollWheelZoom: false,
      attributionControl: false,
    });

    L.imageOverlay(svgURL, bounds, {
      interactive: false,
    }).addTo(map);

    map.fitBounds(bounds);
    map.setMinZoom(map.getZoom());
    map.setMaxZoom(map.getMinZoom() + 5);

    setMap(map);

    return () => {
      map.off();
      map.remove();
      setMap(null);
    };
  }, [svgURL, mapSize]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const computeZoomStatus = () => {
      const zoom = map?.getZoom();
      const minZoom = map?.getMinZoom();
      const maxZoom = map?.getMaxZoom();

      setZoomStatus({ zoomIn: zoom < maxZoom, zoomOut: zoom > minZoom });
    };

    const enableMouseWheel = () => {
      map.scrollWheelZoom.enable();
    };

    const disableMouseWheel = () => {
      map.scrollWheelZoom.disable();
    };

    computeZoomStatus();

    map.on(`focus`, enableMouseWheel);
    map.on(`blur`, disableMouseWheel);
    map.on(`zoomend`, computeZoomStatus);

    return () => {
      map.off(`focus`, enableMouseWheel);
      map.off(`blur`, disableMouseWheel);
      map.off(`zoomend`, computeZoomStatus);
    };
  }, [map]);

  return (
    <div className={wrapperClassName}>
      <div
        ref={ref}
        className={styles.map}
        style={{ height: `${mapSize.height}px` }}
      >
        <div className={controlsLeftClassName}>
          <button className={styles.button} {...fullscreenToggleButton}>
            {isFullscreen ? (
              <CloseFullscreenIcon className={styles.icon} />
            ) : (
              <OpenFullscreenIcon className={styles.icon} />
            )}
          </button>
        </div>
        <div className={controlsRightClassName}>
          <button {...zoomInButton} className={styles.button}>
            <PlusIcon className={styles.icon} />
          </button>
          <button className={styles.button} {...zoomOutButton}>
            <MinusIcon className={styles.icon} />
          </button>
        </div>
      </div>
    </div>
  );
};
