import { useEffect, useRef } from 'react';
import type { MouseEvent } from 'react';

import { Container } from '../components';
import {
  useAccessibility,
  useDisclosure,
  useTranslate,
  useScrollLock,
  useClassName,
  useTransition,
  useLiveStatus,
} from '../hooks';

import { LanguageSwitcher } from './header/language-switcher';
import { LiveStatus } from './header/live-status';
import { LiveToggle } from './header/live-toggle';
import { MenuToggle } from './header/menu-toggle';
import { Nav } from './header/nav';
import { Toolbar } from './header/toolbar';
import styles from './header.module.scss';

type Props = {
  isMenuVisible: boolean;
  isLiveVisible: boolean;
  onToggleMenu: () => void;
  onToggleLive: () => void;
};

export const Header = ({
  isMenuVisible,
  isLiveVisible,
  onToggleMenu,
  onToggleLive,
}: Props) => {
  const translate = useTranslate();
  const [menuTransitionState, toggleMenuTransition] = useTransition({
    timeout: 300,
    preEnter: true,
  });
  const [liveTransitionState, toggleLiveTransition] = useTransition({
    timeout: 300,
    preEnter: true,
  });
  const overlayRef = useRef<HTMLDivElement>(null);

  const liveStatus = useLiveStatus();
  const accessibility = useAccessibility();

  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[menuTransitionState],
    styles[liveTransitionState],
  ]);
  const overlayClassName = useClassName([
    styles.overlay,
    isLiveVisible || isMenuVisible ? styles.visible : ``,
  ]);

  const { triggerProps: menuTriggerProps, controlProps: menuControlProps } =
    useDisclosure({
      controlExpandedClassName: useClassName([
        styles.menuOverlay,
        styles[menuTransitionState],
      ]),
      controlClassName: useClassName([
        styles.menuOverlay,
        styles[menuTransitionState],
      ]),
      isExpanded: isMenuVisible,
      triggerId: `menu-toggle`,
      onToggle: onToggleMenu,
      label: isMenuVisible
        ? translate(accessibility?.data.menuToggleClose)
        : translate(accessibility?.data.menuToggleOpen),
      type: `menu`,
    });

  const { triggerProps: liveTriggerProps, controlProps: liveControlProps } =
    useDisclosure({
      controlExpandedClassName: useClassName([
        styles.liveOverlay,
        styles[liveTransitionState],
      ]),
      controlClassName: useClassName([
        styles.liveOverlay,
        styles[liveTransitionState],
      ]),
      triggerId: `live-toggle`,
      isExpanded: isLiveVisible,
      onToggle: onToggleLive,
      label: translate(liveStatus?.data.description),
      type: `dialog`,
    });

  const overlayClickHandler = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target !== overlayRef.current) {
      return;
    }

    if (isMenuVisible) {
      onToggleMenu();
    } else if (isLiveVisible) {
      onToggleLive();
    }
  };

  useEffect(() => {
    toggleLiveTransition(isLiveVisible);
  }, [toggleLiveTransition, isLiveVisible]);

  useEffect(() => {
    toggleMenuTransition(isMenuVisible);
  }, [toggleMenuTransition, isMenuVisible]);

  useScrollLock({ isLocked: isMenuVisible || isLiveVisible });

  return (
    <header className={wrapperClassName}>
      <div className={styles.navbar}>
        <Toolbar
          menuToggle={
            <MenuToggle {...menuTriggerProps} isExpanded={isMenuVisible} />
          }
          liveToggle={
            <LiveToggle {...liveTriggerProps} isExpanded={isLiveVisible} />
          }
        />
      </div>
      <div
        className={overlayClassName}
        onClick={overlayClickHandler}
        ref={overlayRef}
      >
        <div {...menuControlProps}>
          <Container min="lg" className={styles.menu}>
            <nav className={styles.nav}>
              <Nav isEnabled={isMenuVisible} />
            </nav>
            <footer className={styles.footer}>
              <LanguageSwitcher isEnabled={isMenuVisible} />
            </footer>
          </Container>
        </div>
        <div {...liveControlProps}>
          <LiveStatus />
        </div>
      </div>
    </header>
  );
};
