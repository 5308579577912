import { graphql } from 'gatsby';
import { useState } from 'react';

import {
  Button,
  Container,
  Field,
  InlineSVG,
  Markdown,
  SROnly,
  Title,
  Trans,
} from '../components';
import {
  useLocale,
  useTranslate,
  useTranslateArray,
  useTranslateObject,
} from '../hooks';
import { stringify } from '../utils';

import { DirectionsOption } from './directions-option';
import styles from './directions.module.scss';

type Props = {
  directions: Queries.Squidex_Directions | undefined;
};

const GOOGLE_MAPS_URL = `https://www.google.com/maps/dir/`;

export const Directions = ({ directions }: Props) => {
  const { locale } = useLocale();
  const translate = useTranslate();
  const translateArray = useTranslateArray();
  const translateObject = useTranslateObject();

  const [origin, setOrigin] = useState<string>(``);
  const [remark, setRemark] = useState<string>(``);
  const [travelmode, setTravelmode] = useState<string>(() =>
    stringify(translateArray(directions?.data?.options)?.[0]?.travelmode),
  );

  return (
    <div className={styles.wrapper}>
      <InlineSVG
        wrapper="div"
        className={styles.map}
        svg={
          translateObject(directions?.data.map)?.[0]?.localFile?.svg?.content
        }
      />
      <form
        action={GOOGLE_MAPS_URL}
        target="_blank"
        className={styles.form}
        onSubmit={(event) => {
          if (remark) {
            event.preventDefault();
          }
        }}
      >
        <Container>
          <Title>
            <Trans data={directions?.data?.title} />
          </Title>
          <Markdown
            content={translate(directions?.data.content)}
            className={styles.content}
          />
          <Field.Hidden inputProps={{ name: `hl`, value: locale }} />
          <Field.Hidden inputProps={{ name: `api`, value: `1` }} />
          <Field.Hidden
            inputProps={{
              name: `destination`,
              value: `${directions?.flatData.destination.latitude},${directions?.flatData.destination.longitude}`,
            }}
          />
          <Field.Text
            label={translate(directions?.data?.origin)}
            className={styles.origin}
            inputProps={{
              name: `origin`,
              value: origin,
              onChange: setOrigin,
            }}
          />
          <fieldset className={styles.options}>
            <legend>
              <Trans data={directions?.data?.travelmode} />
            </legend>
            {translateArray(directions?.data.options).map((option) => (
              <DirectionsOption
                option={option}
                key={option.travelmode}
                isChecked={travelmode === option.travelmode}
                onToggle={(option) =>
                  setTravelmode(stringify(option.travelmode))
                }
              />
            ))}
          </fieldset>
          <SROnly aria-hidden={true}>
            <Field.Text
              label=""
              inputProps={{
                value: remark,
                onChange: setRemark,
                autoComplete: `off`,
                className: styles.remark,
              }}
            />
          </SROnly>
          <Button>
            <Trans data={directions?.data?.button} />
          </Button>
        </Container>
      </form>
    </div>
  );
};

export const query = graphql`
  fragment Directions on Squidex {
    queryDirectionsContents {
      data {
        title {
          de
          en
        }
        content {
          de
          en
        }
        origin {
          de
          en
        }
        travelmode {
          de
          en
        }
        options {
          de {
            ...DirectionsOptions
          }
          en {
            ...DirectionsOptions
          }
        }
        button {
          de
          en
        }
        map {
          de {
            ...DirectionsMap
          }
          en {
            ...DirectionsMap
          }
        }
      }
      flatData {
        destination
      }
    }
  }

  fragment DirectionsOptions on Squidex_DirectionsDataOptionsChildDto {
    name
    travelmode
    icon {
      localFile {
        svg {
          content
        }
      }
      ...SquidexAssetLocalFileProps
    }
  }

  fragment DirectionsMap on Squidex_Asset {
    localFile {
      svg {
        content: originalContent
      }
    }
    ...SquidexAssetLocalFileProps
  }
`;
