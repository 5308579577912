import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { isActiveSeason } from '../../utils';

import { useSettings } from './use-settings';

export const useFacilities = (): readonly Queries.Squidex_Facilities[] => {
  const {
    squidex: { queryFacilitiesContents: facilities },
  } = useStaticQuery<Queries.Query>(query);

  return useMemo(() => facilities || [], []);
};

export const useActiveFacilities =
  (): readonly Queries.Squidex_Facilities[] => {
    const settings = useSettings();
    const facilities = useFacilities();

    return useMemo(
      () =>
        facilities.filter((facility) =>
          isActiveSeason(facility.flatData.season, settings),
        ),
      [facilities, settings],
    );
  };

const query = graphql`
  query useFacilitiesQuery {
    squidex {
      queryFacilitiesContents(orderby: "data/rank/iv asc") {
        id
        data {
          name {
            de
            en
          }
        }
        flatData {
          status
          season {
            flatData {
              alias
            }
          }
          icon {
            localFile {
              svg {
                content
              }
            }
            ...SquidexAssetLocalFileProps
          }
        }
      }
    }
  }
`;
