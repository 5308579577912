import { useState } from 'react';
import type { ComponentProps } from 'react';

import { AccordionControlled } from './accordion-controlled';

type AccordionProps = ComponentProps<typeof AccordionControlled>;

type Props = Omit<ComponentProps<typeof AccordionControlled>, 'onToggle'> &
  Partial<Pick<AccordionProps, 'onToggle'>>;

export const Accordion = ({
  selectedIndex: initialSelectedIndex = undefined,
  selectedItems: initialSelectedItems = {},
  autoClose = false,
  onToggle = () => {},
  ...props
}: Props) => {
  const [selectedItems, setSelectedItems] = useState<
    AccordionProps['selectedItems']
  >(autoClose ? {} : initialSelectedItems);
  const [selectedIndex, setSelectedIndex] = useState<
    AccordionProps['selectedIndex']
  >(autoClose ? initialSelectedIndex : undefined);

  const toggleHandler: AccordionProps['onToggle'] = (
    selectedIndex,
    selectedItems,
  ) => {
    setSelectedItems(selectedItems);
    setSelectedIndex(selectedIndex);
    onToggle?.(selectedIndex, selectedItems);
  };

  return autoClose ? (
    <AccordionControlled
      {...props}
      autoClose={true}
      selectedIndex={selectedIndex}
      onToggle={toggleHandler}
    />
  ) : (
    <AccordionControlled
      {...props}
      autoClose={false}
      selectedItems={selectedItems}
      onToggle={toggleHandler}
    />
  );
};

Accordion.Item = AccordionControlled.Item;
