import { Fragment } from 'react';

import { useClassName } from '../../../hooks';
import type { InputProps } from '../types';

import styles from './select.module.scss';

export type Props<T> = InputProps<T, HTMLSelectElement> & {
  options: T[];
  getOptionValue: (option: T) => string;
  getOptionLabel: (option: T) => string;
};

export function SelectField<T>({
  value,
  options,
  className = undefined,
  onChange,
  getOptionLabel,
  getOptionValue,
  ...props
}: Props<T>) {
  const selectValue = getOptionValue(value);
  const selectClassName = useClassName([styles.wrapper, className]);

  return (
    <Fragment>
      <select
        {...props}
        value={selectValue}
        className={selectClassName}
        onChange={(event) => onChange?.(options[event.target.selectedIndex])}
      >
        <option key="default" value=""></option>
        {options.map((option, index) => {
          const value = getOptionValue(option);
          const label = getOptionLabel(option);
          const key = `${index}-${value}`;

          return (
            <option key={key} value={value}>
              {label}
            </option>
          );
        })}
      </select>
      <div className={styles.arrow} />
    </Fragment>
  );
}
