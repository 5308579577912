import { isString } from './type-guards';

export const isValidUrl = (value: unknown): value is string => {
  if (!isString(value)) {
    return false;
  }

  let url;

  try {
    url = new URL(value);
  } catch {
    return false;
  }

  return url.protocol === `http:` || url.protocol === `https:`;
};
