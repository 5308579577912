import { useContext } from 'react';
import type { ReactNode } from 'react';

import { useClassName, useTab } from '../../hooks';
import { RovingIndexItem } from '../a11y';

import { CarouselTabContext } from './carousel-tab-context';
import styles from './carousel-tab.module.scss';

type Props = {
  label: string;
  children?: (isSelected: boolean) => ReactNode;
};

export const CarouselTab = ({
  label,
  children = (isSelected) => (isSelected ? `\u25CF` : `\u25CB`),
}: Props) => {
  const { itemId, isSelected, select } = useContext(CarouselTabContext);
  const wrapperClassName = useClassName([
    styles.wrapper,
    isSelected ? styles.selected : ``,
  ]);

  const { triggerProps } = useTab({
    label,
    isSelected,
    onSelect: select,
    controlId: itemId,
  });

  return (
    <RovingIndexItem
      options={{
        onFocusIn: () => select(),
      }}
    >
      {(rovingItem) => (
        <button {...triggerProps} {...rovingItem} className={wrapperClassName}>
          {children(isSelected)}
        </button>
      )}
    </RovingIndexItem>
  );
};
