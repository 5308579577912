import { navigate } from 'gatsby';
import { marked } from 'marked';
import type { HTMLAttributes, MouseEvent } from 'react';

import { isElement, isString } from '../../utils';

import { HTML } from './html';

const { parse, parseInline } = marked;

type Props = {
  content?: string | null;
  isInline?: boolean;
} & (
  | ({
      wrapper?: `div`;
    } & Omit<HTMLAttributes<HTMLDivElement>, 'content'>)
  | ({
      wrapper?: `span`;
    } & Omit<HTMLAttributes<HTMLSpanElement>, 'content'>)
  | ({
      wrapper?: `p`;
    } & Omit<HTMLAttributes<HTMLParagraphElement>, 'content'>)
);

export const Markdown = ({ content, isInline, ...props }: Props) =>
  isString(content) && content.length > 0 ? (
    <HTML
      content={isInline ? parseInline(content) : parse(content)}
      onClick={(
        event: MouseEvent<
          HTMLDivElement | HTMLSpanElement | HTMLParagraphElement
        >,
      ) => {
        if (isElement(event.target)) {
          switch (event.target.tagName) {
            case `A`: {
              const href = event.target.getAttribute(`href`);
              if (isString(href)) {
                event.preventDefault();
                if (!/^\/(?!\/)/.test(href)) {
                  window.open(href, `_blank`, `noopener`);
                } else {
                  navigate(href);
                }
              }
              break;
            }
            default:
          }
        }
      }}
      data-markdown={true}
      {...props}
    />
  ) : null;
