import type { HTMLAttributes, ReactNode } from 'react';

import { useClassName } from '../../hooks';
import { isString, isNumber, truncate } from '../../utils';

import styles from './text.module.scss';

export type Props = {
  children: ReactNode;
  color?: `black` | `white` | `grey` | `primary` | `secondary`;
  family?: `ff-1`;
  size?: `fs-1` | `fs-2` | `fs-3` | `fs-4` | `fs-5` | `fs-6`;
  weight?: `fw-500` | `fw-700`;
  transform?: `uppercase` | `lowercase`;
  maxLength?: number;
  className?: string;
} & (
  | ({
      wrapper?: `div`;
    } & HTMLAttributes<HTMLDivElement>)
  | ({
      wrapper?: `span`;
    } & HTMLAttributes<HTMLSpanElement>)
  | ({
      wrapper?: `p`;
    } & HTMLAttributes<HTMLParagraphElement>)
);

export const Text = ({
  children,
  wrapper: Wrapper = `span`,
  color = undefined,
  family = undefined,
  size = undefined,
  weight = undefined,
  transform = undefined,
  className = undefined,
  maxLength = undefined,
  ...domProps
}: Props) => {
  const content =
    isString(children) && isNumber(maxLength)
      ? truncate(children, maxLength)
      : children;
  const wrapperClassName = useClassName([
    color ? styles[color] : ``,
    family ? styles[family] : ``,
    size ? styles[size] : ``,
    weight ? styles[weight] : ``,
    transform ? styles[transform] : ``,
    className,
  ]);

  return (
    <Wrapper {...domProps} className={wrapperClassName}>
      {content}
    </Wrapper>
  );
};
