import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import {
  filterActivePages,
  filterInternalPages,
  filterPages,
} from '../../utils';

import { useSettings } from './use-settings';

export const usePages = (): readonly Queries.Squidex_Pages[] => {
  const {
    squidex: { queryPagesContents: pages },
  } = useStaticQuery<Queries.Query>(query);

  return useMemo(() => pages || [], []);
};

export const useActivePages = (
  pages: Queries.Maybe<readonly Queries.Squidex_Pages[]>,
): readonly Queries.Squidex_Pages[] => {
  const settings = useSettings();

  return useMemo(
    () => filterInternalPages(filterActivePages(pages, settings)),
    [pages, settings],
  );
};

export const usePagesByView = (
  view: `main` | `footer`,
  pages?: Queries.Maybe<readonly Queries.Squidex_Pages[]>,
): readonly Queries.Squidex_Pages[] => {
  const allPages = usePages();
  return useMemo(
    () =>
      filterPages(pages || allPages, (page) =>
        (page.flatData.view || []).includes(view),
      ),
    [pages, view],
  );
};

export const usePageByAlias = (
  alias: string,
): Queries.Squidex_Pages | undefined => {
  const allPages = usePages();
  return useMemo(
    () => filterPages(allPages, (page) => page.flatData.alias === alias)[0],
    [allPages, alias],
  );
};

const query = graphql`
  query usePagesQuery {
    squidex {
      queryPagesContents(orderby: "data/rank/iv asc") {
        ...Page
      }
    }
  }

  fragment Page on Squidex_Pages {
    id
    data {
      path {
        de
        en
      }
      title {
        de
        en
      }
      description {
        de
        en
      }
    }

    flatData {
      view
      rank
      path
      alias
      parent {
        flatData {
          alias
        }
      }
      season {
        flatData {
          alias
        }
      }
    }
  }
`;
