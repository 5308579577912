import type { InputProps } from '../types';

import { Input } from './input';

export type Props = InputProps<string>;

export const EmailField = ({ onChange, ...props }: Props) => (
  <Input
    type="email"
    inputMode="email"
    onChange={(event) => onChange?.(event.target.value)}
    {...props}
  />
);
