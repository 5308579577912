import { useContext } from 'react';
import type { ComponentProps, ReactElement } from 'react';

import { useTabList } from '../../hooks';
import { RovingIndexContainer } from '../a11y';

import { CarouselContext } from './carousel-context';
import { CarouselTab } from './carousel-tab';
import { CarouselTabContext } from './carousel-tab-context';
import styles from './carousel-tabs.module.scss';

type CarouselControlProps = ComponentProps<typeof CarouselTab>;
type CarouselControlType = ReactElement<CarouselControlProps>;

type Props = {
  label?: string;
  children?: (index: number) => CarouselControlType;
};

export const CarouselTabs = ({
  label = `Slides`,
  children = (index) => <CarouselTab label={`Slide ${index + 1}`} />,
}: Props) => {
  const { selectItem, isItemSelected, makeItemId, itemCount } =
    useContext(CarouselContext);
  const tabList = useTabList({ label, orientation: `horizontal` });

  return (
    <RovingIndexContainer direction="horizontal">
      {(rovingContainer) => (
        <div {...tabList} {...rovingContainer} className={styles.wrapper}>
          {Array.from({ length: itemCount }).map((_, index) => (
            <CarouselTabContext.Provider
              key={makeItemId(index)}
              value={{
                itemId: makeItemId(index),
                select: () => selectItem(index),
                isSelected: isItemSelected(index),
              }}
            >
              {children(index)}
            </CarouselTabContext.Provider>
          ))}
        </div>
      )}
    </RovingIndexContainer>
  );
};
