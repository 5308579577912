import type { ComponentProps, ReactElement } from 'react';

import styles from './carousel-controls.module.scss';
import { CarouselNext } from './carousel-next';
import { CarouselPrev } from './carousel-prev';
import { CarouselTabs } from './carousel-tabs';

type CarouselPrevProps = ComponentProps<typeof CarouselPrev>;
type CarouselNextProps = ComponentProps<typeof CarouselNext>;
type CarouselTabsProps = ComponentProps<typeof CarouselTabs>;

type CarouselPrevType = ReactElement<CarouselPrevProps>;
type CarouselNextType = ReactElement<CarouselNextProps>;
type CarouselTabsType = ReactElement<CarouselTabsProps>;

type Props = {
  children:
    | CarouselPrevType
    | CarouselNextType
    | CarouselTabsType
    | (CarouselPrevType | CarouselNextType | CarouselTabsType)[];
};

export const CarouselControls = ({ children }: Props) => (
  <div className={styles.wrapper}>{children}</div>
);
