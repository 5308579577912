import { createContext } from 'react';

type Context = {
  tabId: string;
  panelId: string;
  isSelected: boolean;
  select: () => void;
};

export const TabContext = createContext<Context>({
  tabId: ``,
  panelId: ``,
  isSelected: false,
  select: () => {},
});
