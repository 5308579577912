import { useEffect, useState } from 'react';

export const useKeyPress = (key: string): boolean => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  const downHandler = (event: KeyboardEvent): void => {
    if (event.key === key) {
      setKeyPressed(true);
    }
  };

  const upHandler = (event: KeyboardEvent): void => {
    if (event.key === key) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener(`keydown`, downHandler);
    window.addEventListener(`keyup`, upHandler);

    return () => {
      window.removeEventListener(`keydown`, downHandler);
      window.removeEventListener(`keyup`, upHandler);
    };
  }, []);

  return keyPressed;
};
