import { useEffect, useState, useRef } from 'react';

type Props = {
  date: Date;
  locale?: string;
  prefix?: string;
  suffix?: string;
  options?: Intl.DateTimeFormatOptions;
};

const formatDate = (
  date: Date,
  locale?: string,
  options?: Intl.DateTimeFormatOptions,
) => date.toLocaleDateString(locale, options);

export const useFormatDate = ({
  date,
  locale,
  prefix,
  suffix,
  options = {
    dateStyle: `full`,
  },
}: Props): string => {
  const optionsRef = useRef<Props['options']>(options);
  const [formattedDate, setFormattedDate] = useState(() =>
    formatDate(date, locale, { ...optionsRef.current, timeZone: `UTC` }),
  );

  useEffect(
    () => setFormattedDate(formatDate(date, locale, optionsRef.current)),
    [date, locale],
  );

  return `${prefix ? prefix + ` ` : ``}${formattedDate}${
    suffix ? ` ` + suffix : ``
  }`;
};
