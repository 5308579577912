import type { PageProps } from 'gatsby';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';

import type { PageContext, PageData } from '../config';
import { useClassName, useKeyPress, useLocale } from '../hooks';

import { Footer } from './footer';
import { Header } from './header';
import styles from './layout.module.scss';
import { Notification } from './notification';
import { Scripts } from './scripts';

type Props = Omit<PageProps<PageData, PageContext>, 'children'> & {
  children: ReactElement;
};

export const Layout = ({
  location,
  pageContext: { locale },
  children,
}: Props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isLiveVisible, setIsLiveVisible] = useState(false);
  const isEscapePressed = useKeyPress(`Escape`);
  const { setLocale } = useLocale();

  const mainClassName = useClassName([
    styles.main,
    isMenuVisible || isLiveVisible ? styles.overlay : ``,
  ]);
  const showNotification = !isMenuVisible && !isLiveVisible;

  const toggleMenuHandler = () => {
    setIsMenuVisible((isMenuVisible) => !isMenuVisible);
  };

  const toggleLiveHandler = () => {
    setIsLiveVisible((isLiveVisible) => !isLiveVisible);
  };

  useEffect(() => {
    if (isLiveVisible) {
      setIsMenuVisible(false);
    }
  }, [isLiveVisible]);

  useEffect(() => {
    if (isMenuVisible) {
      setIsLiveVisible(false);
    }
  }, [isMenuVisible]);

  useEffect(() => {
    if (isEscapePressed) {
      setIsLiveVisible(false);
      setIsMenuVisible(false);
    }
  }, [isEscapePressed]);

  useEffect(() => {
    setIsMenuVisible(false);
    setIsLiveVisible(false);
  }, [location.href]);

  useEffect(() => {
    setLocale(locale);
  }, [setLocale, locale]);

  return (
    <div className={styles.wrapper}>
      {showNotification && <Notification className={styles.notification} />}
      <Header
        isMenuVisible={isMenuVisible}
        isLiveVisible={isLiveVisible}
        onToggleMenu={toggleMenuHandler}
        onToggleLive={toggleLiveHandler}
      />
      <main className={mainClassName}>{children}</main>
      <Footer />
      <Scripts pathname={location.pathname} />
    </div>
  );
};
