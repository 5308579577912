import { graphql, useStaticQuery } from 'gatsby';

import { isActiveSeason } from '../../utils';

export const useSettings = (): Queries.Squidex_Settings | undefined => {
  const data = useStaticQuery<Queries.Query>(query);

  return data.squidex.querySettingsContents?.[0];
};

export const useIsActiveSeason = (
  seasons: Queries.Maybe<readonly Queries.Squidex_Seasons[]> | undefined,
): boolean => {
  const settings = useSettings();
  return isActiveSeason(seasons, settings);
};

const query = graphql`
  query useSettingsQuery {
    squidex {
      querySettingsContents {
        flatData {
          season {
            flatData {
              alias
            }
          }
        }
      }
    }
  }
`;
