import { Locale } from '../../config';
import { isString, stringify } from '../../utils';
import { useLocale } from '../contexts';

type Data = Record<Locale, string | null> | string | null | undefined;
type TFunction = (data: Data) => string;

export const useTranslate = (): TFunction => {
  const { locale } = useLocale();
  return (data: Data): string =>
    !isString(data) ? stringify(data?.[locale]) : data;
};
