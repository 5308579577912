import { Link } from 'gatsby';
import { Fragment } from 'react';

import {
  Slopes,
  Facilities,
  WeatherOverall,
  WebcamsPreview,
} from '../../common';
import { Button, Divider, Trans } from '../../components';
import {
  useAccessibility,
  useActiveFacilities,
  useActiveSlopes,
  usePageByAlias,
  useTranslate,
  useWebcams,
} from '../../hooks';

import styles from './live-status.module.scss';


export const LiveStatus = () => {
  const translate = useTranslate();
  const accessibility = useAccessibility();

  const webcams = useWebcams();
  const facilities = useActiveFacilities();
  const slopes = useActiveSlopes();
  const detailPage = usePageByAlias(`live-status`);

  return (
    <div className={styles.wrapper}>
      <WeatherOverall />
      {facilities.length > 0 ? (
        <Fragment>
          <Facilities facilities={facilities} />
          <Divider size="sm" color="grey" />
        </Fragment>
      ) : null}
      {slopes.length > 0 ? (
        <Fragment>
          <Slopes slopes={slopes} />
          <Divider size="sm" color="grey" />
        </Fragment>
      ) : null}
      <WebcamsPreview webcams={webcams} />
      <footer className={styles.footer}>
        <Button wrapper={<Link to={translate(detailPage?.data.path)} />}>
          <Trans data={accessibility?.data.moreDetails} />
        </Button>
      </footer>
    </div>
  );
};
