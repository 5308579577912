import { useClassName } from '../../../hooks';
import type { InputProps } from '../types';

import styles from './text-area.module.scss';

export type Props = InputProps<string, HTMLTextAreaElement>;

export const TextAreaField = ({
  className = undefined,
  onChange,
  ...props
}: Props) => {
  const textAreaClassName = useClassName([styles.wrapper, className]);

  return (
    <textarea
      {...props}
      className={textAreaClassName}
      onChange={(event) => onChange?.(event.target.value)}
    />
  );
};
