import { Copyright } from './footer/copyright';
import { Nav } from './footer/nav';
import styles from './footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      <nav className={styles.nav}>
        <Nav />
      </nav>
      <Copyright />
    </footer>
  );
};
