import { Fragment } from 'react';
import type { ReactElement, ReactNode } from 'react';

export type Props = {
  condition: boolean;
  children: ReactNode;
  wrapper: (children: ReactNode) => ReactElement;
  fallback?: (children: ReactNode) => ReactElement;
};

export const Conditional = ({
  condition,
  wrapper,
  children,
  fallback = (children) => <Fragment>{children}</Fragment>,
}: Props) => (condition ? wrapper(children) : fallback(children));
