import { Children, isValidElement, useId, useState } from 'react';
import type { ComponentProps, ReactElement } from 'react';

import { SELECTION_STATE } from './carousel-constants';
import type { SelectionState } from './carousel-constants';
import { CarouselContext } from './carousel-context';
import styles from './carousel-controlled.module.scss';
import { CarouselControls } from './carousel-controls';
import { CarouselItem } from './carousel-item';
import { CarouselItems } from './carousel-items';
import { CarouselNext } from './carousel-next';
import { CarouselPrev } from './carousel-prev';
import { CarouselTab } from './carousel-tab';
import { CarouselTabs } from './carousel-tabs';

type CarouselItemsProps = ComponentProps<typeof CarouselItems>;
type CarouselControlsProps = ComponentProps<typeof CarouselControls>;

type CarouselItemsType = ReactElement<CarouselItemsProps>;
type CarouselControlsType = ReactElement<CarouselControlsProps>;

type Props = {
  children: CarouselItemsType | (CarouselItemsType | CarouselControlsType)[];
  selectedIndex: number;
  isInfinite?: boolean;
  autoHeight?: boolean;
  onSelect: (selectedIndex: number) => void;
};

export const CarouselControlled = ({
  children,
  selectedIndex,
  isInfinite = false,
  autoHeight = false,
  onSelect,
}: Props) => {
  const id = useId();
  const itemsId = useId();
  const [selectionState, setSelectionState] = useState<SelectionState>(
    SELECTION_STATE.RESOLVED,
  );
  const makeItemId = (index: number) => `${id}item-${index}`;
  const isItemSelected = (index: number) => selectedIndex === index;
  const selectItem = (index: number) => {
    setSelectionState(SELECTION_STATE.SELECTED);
    onSelect(index);
  };

  const resolveItem = (index: number) => {
    setSelectionState(SELECTION_STATE.RESOLVED);
    if (selectedIndex === index) {
      return;
    }

    onSelect(index);
  };

  const prevItem = () => {
    let prevSelectedIndex = selectedIndex - 1;
    prevSelectedIndex =
      prevSelectedIndex >= 0
        ? prevSelectedIndex
        : isInfinite
        ? itemCount - 1
        : selectedIndex;

    selectItem(prevSelectedIndex);
  };

  const nextItem = () => {
    let nextSelectedIndex = selectedIndex + 1;
    nextSelectedIndex =
      nextSelectedIndex <= itemCount - 1
        ? nextSelectedIndex
        : isInfinite
        ? 0
        : selectedIndex;

    selectItem(nextSelectedIndex);
  };

  const [items] = Children.toArray(children).filter(
    (element: unknown): element is CarouselItemsType =>
      isValidElement(element) && element.type === CarouselItems,
  );

  const itemCount = Children.toArray(items?.props?.children).length;
  const hasNext = !isInfinite ? selectedIndex < itemCount - 1 : true;
  const hasPrev = !isInfinite ? selectedIndex > 0 : true;

  return (
    <CarouselContext.Provider
      value={{
        hasPrev,
        hasNext,
        itemsId,
        itemCount,
        autoHeight,
        selectionState,
        nextItem,
        prevItem,
        makeItemId,
        selectItem,
        resolveItem,
        isItemSelected,
      }}
    >
      <div aria-roledescription="carousel" className={styles.wrapper}>
        {children}
      </div>
    </CarouselContext.Provider>
  );
};

CarouselControlled.Items = CarouselItems;
CarouselControlled.Item = CarouselItem;

CarouselControlled.Controls = CarouselControls;
CarouselControlled.Next = CarouselNext;
CarouselControlled.Prev = CarouselPrev;
CarouselControlled.Tabs = CarouselTabs;
CarouselControlled.Tab = CarouselTab;
