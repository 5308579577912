import { isValidElement, Children, useContext } from 'react';
import type { ComponentProps, ReactElement, HTMLAttributes } from 'react';

import { useClassName, useTabList } from '../../hooks';
import { RovingIndexContainer } from '../a11y';

import { Tab } from './tab';
import { TabContext } from './tab-context';
import styles from './tab-list.module.scss';
import { TabsContext } from './tabs-context';


type TabProps = ComponentProps<typeof Tab>;
type TabType = ReactElement<TabProps>;

type Props = {
  children: TabType | TabType[];
  size?: `auto` | `fullwidth`;
} & HTMLAttributes<HTMLDivElement> &
  (
    | {
        label: string;
        labelId?: never;
      }
    | {
        label?: never;
        labelId: string;
      }
  );

export const TabList = ({ children, size = `auto`, ...props }: Props) => {
  const { direction, isTabSelected, selectTab, makeTabId, makePanelId } =
    useContext(TabsContext);
  const tabList = useTabList({ ...props, orientation: direction });
  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[size],
    styles[direction],
  ]);

  const tabs = Children.toArray(children).filter(
    (element: unknown): element is TabType =>
      isValidElement(element) && element.type === Tab,
  );

  return (
    <RovingIndexContainer direction={direction}>
      {(rovingContainer) => (
        <div {...rovingContainer} {...tabList} className={wrapperClassName}>
          {tabs.map((tab, index) => (
            <TabContext.Provider
              key={makeTabId(index)}
              value={{
                tabId: makeTabId(index),
                panelId: makePanelId(index),
                select: () => selectTab(index),
                isSelected: isTabSelected(index),
              }}
            >
              {tab}
            </TabContext.Provider>
          ))}
        </div>
      )}
    </RovingIndexContainer>
  );
};
