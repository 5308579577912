import { isString } from './type-guards';

export const truncate = (value: string, maxLength: number) =>
  value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;

export const hashCode = (value: string): number => {
  let hash = 0;
  for (let i = 0, length = value.length; i < length; i++) {
    const char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }

  return hash;
};

export const stringify = (value: unknown): string =>
  isString(value) ? value : ``;
