import type { AnchorHTMLAttributes, ReactElement } from 'react';

import { useClassName } from '../../hooks';

import styles from './anchor.module.scss';

type Props<T> =
  | ({
      wrapper: `a`;
    } & AnchorHTMLAttributes<HTMLAnchorElement>)
  | {
      wrapper?: ReactElement<T>;
      className?: string;
    };

export function Anchor<T>({
  wrapper = `a`,
  className = undefined,
  ...props
}: Props<T>) {
  const element =
    wrapper === `a` ? <a {...props} className={className} /> : wrapper;

  const wrapperClassName = useClassName([
    styles.wrapper,
    element?.props.className,
  ]);

  return <element.type {...element.props} className={wrapperClassName} />;
}
