import { Fragment, useContext } from 'react';
import type { ReactNode } from 'react';

import { useClassName, useDisclosure } from '../../hooks';
import { PlusIcon } from '../../icons';

import { AccordionItemContext } from './accordion-item-context';
import styles from './accordion-item.module.scss';
import { Title } from './title';
import type { Props as HeaderProps } from './title';


type Props = {
  label: ReactNode;
  children: ReactNode;
  panelRole?: 'region';
  headerWrapper?: HeaderProps['wrapper'];
};

export const AccordionItem = ({
  label,
  children,
  panelRole = `region`,
  headerWrapper = `h3`,
}: Props) => {
  const { isSelected, toggle, theme } = useContext(AccordionItemContext);

  const wrapperClassName = useClassName([styles.wrapper, styles[theme]]);
  const buttonClassName = useClassName([
    styles.button,
    isSelected ? styles.expanded : ``,
  ]);

  const { triggerProps, controlProps } = useDisclosure({
    isExpanded: isSelected,
    onToggle: toggle,
    controlClassName: styles.panel,
    controlExpandedClassName: useClassName([styles.panel, styles.expanded]),
    controlBindLabelToButton: true,
  });

  return (
    <div className={wrapperClassName}>
      <Title wrapper={headerWrapper} className={styles.header}>
        <button {...triggerProps} className={buttonClassName}>
          {label}
          <PlusIcon aria-hidden={true} className={styles.icon} />
        </button>
      </Title>
      <div {...controlProps} role={panelRole}>
        {children}
      </div>
    </div>
  );
};
