import { useId } from 'react';

import { Container, Title, Text, Trans } from '../components';
import type { Locale } from '../config';
import { useClassName, useTranslate } from '../hooks';

import { Image } from './image';
import styles from './page-header.module.scss';


type Props = {
  image?: Queries.Squidex_Asset;
  title?: Queries.Maybe<Record<Locale, Queries.Maybe<string>>> | string;
  subtitle?: Queries.Maybe<Record<Locale, Queries.Maybe<string>>> | string;
};

export const PageHeader = ({ image, title, subtitle }: Props) => {
  const titleId = useId();
  const translate = useTranslate();
  const wrapperClassName = useClassName([
    styles.wrapper,
    image ? styles.bgImage : styles.bgColor,
  ]);

  return (
    <header className={wrapperClassName} aria-labelledby={titleId}>
      <div className={styles.content}>
        <Container>
          <Title id={titleId} wrapper="h1" className={styles.title}>
            <Trans data={title} />
          </Title>
          {subtitle && (
            <Text size="fs-3">
              <Trans data={subtitle} />
            </Text>
          )}
        </Container>
      </div>

      <Image alt={translate(title)} image={image} className={styles.image} />
    </header>
  );
};
