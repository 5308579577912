import {
  ChangeEvent,
  InvalidEvent,
  Fragment,
  useState,
  useEffect,
  useRef,
} from 'react';

import { Button, Field, SROnly, Trans, Text } from '../components';
import type { FieldProps } from '../components';
import { useAccessibility, useTranslate, useTranslateArray } from '../hooks';
import { stringify } from '../utils';

import styles from './contact-form.module.scss';

const API_URL = `${process.env.GATSBY_BACKEND_API_URL}/send-message`;

const TRANSMISSION_STATUS = {
  IDLE: `idle`,
  SENDING: `sending`,
  SUCCESS: `success`,
  FAILURE: `failure`,
} as const;
type TransmissionStatus =
  (typeof TRANSMISSION_STATUS)[keyof typeof TRANSMISSION_STATUS];

function fieldFactory<T extends FieldProps<any>>(
  type: Queries.Maybe<Queries.Squidex_PageContactDataFormTypeEnum>,
  props: T,
) {
  switch (type) {
    case `checkbox`:
      return <Field.Checkbox {...props} />;
    case `date`:
      return <Field.Date {...props} />;
    case `email`:
      return <Field.Email {...props} />;
    case `number`:
      return <Field.Number {...props} />;
    case `select`:
      return <Field.Select {...props} />;
    case `text`:
      return <Field.Text {...props} />;
    case `textarea`:
      return <Field.TextArea {...props} />;
    default:
      return null;
  }
}

type Props = {
  pageContact: Queries.Squidex_PageContact | undefined;
};

export const ContactForm = ({ pageContact }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [transmissionStatus, setTransmissionStatus] =
    useState<TransmissionStatus>(TRANSMISSION_STATUS.IDLE);
  const [formState, setFormState] = useState<Record<string, string>>({});
  const accessibility = useAccessibility();
  const translateArray = useTranslateArray();
  const translate = useTranslate();

  const errorMessage = translate(accessibility?.data.validationMessage);

  const makeInputProps = (name: string) => ({
    name,
    required: true,
    value: stringify(formState[name]),
    onInvalid: ({ target }: InvalidEvent<any>) => {
      // set when send without interactoin
      target.setCustomValidity(errorMessage);
    },
    onInput: ({ target }: ChangeEvent<any>) => {
      // 1) reset potential custom error
      target.setCustomValidity(``);

      // 2) if still error, override again
      if (!target.validity.valid) {
        target.setCustomValidity(errorMessage);
      }
    },
    onChange: (value: string) =>
      setFormState((formState) => ({ ...formState, [name]: value })),
  });

  const sendMessage = async () => {
    const response = await fetch(API_URL, {
      method: `POST`,
      body: JSON.stringify(formState),
      headers: {
        'Content-Type': `application/json`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed with response: ${await response.text()}`);
    }

    setFormState({});
  };

  useEffect(() => {
    switch (transmissionStatus) {
      case TRANSMISSION_STATUS.IDLE:
        dialogRef.current?.close();
        break;
      default:
        dialogRef.current?.showModal();
    }

    return () => dialogRef.current?.close();
  }, [transmissionStatus]);

  return (
    <Fragment>
      <form
        className={styles.wrapper}
        onSubmit={async (event) => {
          event.preventDefault();
          if (formState.remark) {
            return;
          }

          try {
            setTransmissionStatus(TRANSMISSION_STATUS.SENDING);
            await sendMessage();
            setTransmissionStatus(TRANSMISSION_STATUS.SUCCESS);
          } catch (e) {
            setTransmissionStatus(TRANSMISSION_STATUS.FAILURE);
            console.error(e);
          }
        }}
      >
        <div className={styles.fields}>
          {translateArray(pageContact?.data.form).map(({ type, name, label }) =>
            name ? (
              <Fragment key={name}>
                {fieldFactory(type, {
                  label,
                  className: styles.field,
                  inputProps: makeInputProps(name),
                })}
              </Fragment>
            ) : null,
          )}
        </div>
        <SROnly aria-hidden={true}>
          <Field.Text
            label=""
            inputProps={{
              ...makeInputProps(`remark`),
              required: false,
              autoComplete: `off`,
              className: styles.remark,
            }}
          />
        </SROnly>
        <Button
          color="grey"
          isDisabled={transmissionStatus === TRANSMISSION_STATUS.SENDING}
        >
          <Trans data={pageContact?.data.submit} />
        </Button>
      </form>
      <dialog ref={dialogRef} className={styles.dialog}>
        <div role="status" aria-live="polite">
          {transmissionStatus === TRANSMISSION_STATUS.SENDING ? (
            <Trans data={pageContact?.data.messageLoading} />
          ) : (
            <form method="dialog" className={styles.form}>
              <Text
                weight="fw-700"
                color={
                  transmissionStatus === TRANSMISSION_STATUS.SUCCESS
                    ? `secondary`
                    : `primary`
                }
              >
                <Trans
                  data={
                    transmissionStatus === TRANSMISSION_STATUS.SUCCESS
                      ? pageContact?.data.messageSuccess
                      : pageContact?.data.messageFailure
                  }
                />
              </Text>

              <Button
                className={styles.button}
                color={
                  transmissionStatus === TRANSMISSION_STATUS.SUCCESS
                    ? `secondary`
                    : `primary`
                }
              >
                <Trans data={accessibility?.data.close} />
              </Button>
            </form>
          )}
        </div>
      </dialog>
    </Fragment>
  );
};
