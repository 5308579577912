import { graphql } from 'gatsby';
import { RRule } from 'rrule';


import { Accordion, Markdown, Trans } from '../components';
import { useTranslate } from '../hooks';

import { Event } from './event';

type Props = {
  events: Queries.Maybe<readonly Queries.Squidex_Events[]>;
  pageEvents: Queries.Squidex_PageEvents | undefined;

  maxPreviewInMonths?: number;
  maxEvents?: number;
};

const WEEKDAYS: Record<string, number> = {
  MO: 0,
  TU: 1,
  WE: 2,
  TH: 3,
  FR: 4,
  SA: 5,
  SU: 6,
};

const filterRecurringEvents = (events: Props['events']): Props['events'] =>
  events?.filter((event) => event.flatData.repeatable) || null;
const filterDefaultEvents = (events: Props['events']): Props['events'] =>
  events?.filter((event) => !event.flatData.repeatable) || null;

const sliceEvents = (
  events: Props['events'],
  from: Date,
  to: Date,
): Props['events'] => {
  const startOfDay = new Date(from);
  const toEndOfDay = new Date(to);

  startOfDay.setUTCHours(0, 0, 0, 0);
  toEndOfDay.setUTCHours(23, 59, 59, 999);

  return (
    events?.filter((event) => {
      const start = new Date(event.flatData.startDate);

      return (
        start.getTime() >= startOfDay.getTime() &&
        start.getTime() <= toEndOfDay.getTime()
      );
    }) || null
  );
};

const sortEvents = (events: Props['events']): Props['events'] =>
  [...(events || [])].sort((eventA, eventB) => {
    const startA = new Date(eventA?.flatData.startDate);
    const startB = new Date(eventB?.flatData.startDate);

    return startA.getTime() - startB.getTime();
  });

const generateRecurringEvents = (
  event: Queries.Squidex_Events,
): Queries.Squidex_Events[] => {
  const repeatableWeekdays = event.flatData.repeatableWeekdays?.map(
    (weekday) => WEEKDAYS[weekday],
  );
  const repeatableInterval = event.flatData.repeatableInterval || 1;
  const repeatableUntil = new Date(event.flatData.repeatableUntil);
  const startDate = new Date(event.flatData.startDate);
  const endDate = new Date(event.flatData.endDate || event.flatData.startDate);

  const delta = endDate.getTime() - startDate.getTime();

  const rule = new RRule({
    byweekday: repeatableWeekdays,
    interval: repeatableInterval,
    until: repeatableUntil,
    dtstart: startDate,
    freq: RRule.WEEKLY,
  });

  return rule.all().map((newStartDate) => {
    const newEndDate = new Date(newStartDate);
    newEndDate.setTime(newEndDate.getTime() + delta);

    return {
      ...event,
      flatData: {
        ...event.flatData,
        startDate: newStartDate.toISOString(),
        endDate: delta >= 0 ? newEndDate.toISOString() : endDate.toISOString(),
      },
    };
  });
};

export const Events = ({
  events,
  pageEvents,
  maxEvents,
  maxPreviewInMonths = 6,
}: Props) => {
  const translate = useTranslate();

  const recurringEvents = (filterRecurringEvents(events) || []).flatMap(
    generateRecurringEvents,
  );
  const defaultEvents = sortEvents(filterDefaultEvents(events)) || [];

  const lastDefaultEventDate = new Date(
    defaultEvents[defaultEvents.length - 1].flatData.startDate,
  );
  const lastMaxPreviewEventDate = new Date();
  lastMaxPreviewEventDate.setMonth(
    lastMaxPreviewEventDate.getMonth() + maxPreviewInMonths,
  );
  const maxDate = new Date(
    Math.max(
      ...[lastDefaultEventDate, lastMaxPreviewEventDate].map((date) =>
        date.getTime(),
      ),
    ),
  );

  const slicedEvents = sortEvents([
    ...(sliceEvents(
      [...defaultEvents, ...recurringEvents],
      new Date(),
      maxDate,
    ) || []),
  ]);

  const allEvents = (slicedEvents || []).slice(0, maxEvents);

  return allEvents.length > 0 ? (
    <Accordion theme="light">
      {allEvents.map((event) => (
        <Accordion.Item
          key={event.id}
          label={<Event event={event} pageEvents={pageEvents} />}
        >
          <Markdown content={translate(event.data.content)} />
        </Accordion.Item>
      ))}
    </Accordion>
  ) : (
    <div>
      <Trans data={pageEvents?.data.noEventsMessage} />
    </div>
  );
};

export const query = graphql`
  fragment Events on Squidex {
    queryEventsContents {
      id
      data {
        title {
          de
          en
        }
        content {
          de
          en
        }
      }
      flatData {
        category {
          flatData {
            icon {
              localFile {
                svg {
                  content
                }
              }
              ...SquidexAssetLocalFileProps
            }
          }
        }
        startDate
        endDate
        repeatable
        repeatableUntil
        repeatableWeekdays
        repeatableInterval
      }
    }
  }

  fragment PageEvents on Squidex {
    queryPageEventsContents {
      data {
        title {
          de
          en
        }
        content {
          de
          en
        }
        allEvents {
          de
          en
        }
        noEventsMessage {
          de
          en
        }
        on {
          de
          en
        }
        at {
          de
          en
        }
        from {
          de
          en
        }
        to {
          de
          en
        }
        suffix {
          de
          en
        }
      }
      flatData {
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2560
                height: 1024
                layout: CONSTRAINED
                transformOptions: { cropFocus: ATTENTION }
              )
            }
          }
          ...SquidexAssetLocalFileProps
        }
      }
    }
  }
`;
