import { InlineSVG, Text, Title, Trans } from '../components';
import {
  useFormatDate,
  useFormatTime,
  useLocale,
  useTranslate,
} from '../hooks';

import styles from './event.module.scss';

type Props = {
  event: Queries.Squidex_Events;
  pageEvents: Queries.Squidex_PageEvents | undefined;
};

const isSameDate = (dateA: Date, dateB: Date) =>
  dateA.getUTCFullYear() === dateB.getUTCFullYear() &&
  dateA.getUTCMonth() === dateB.getUTCMonth() &&
  dateA.getUTCDate() === dateB.getUTCDate();

export const Event = ({ event, pageEvents }: Props) => {
  const { locale } = useLocale();
  const translate = useTranslate();

  const startDate = new Date(event.flatData.startDate);
  const endDate = new Date(event.flatData.endDate);

  const labelAt = translate(pageEvents?.data.at);
  const labelOn = translate(pageEvents?.data.on);
  const labelFrom = translate(pageEvents?.data.from);
  const labelTo = translate(pageEvents?.data.to);
  const suffix = translate(pageEvents?.data.suffix);

  const formattedStartDateOn = useFormatDate({
    date: startDate,
    locale,
    prefix: labelOn,
  });
  const formattedStartDateFrom = useFormatDate({
    date: startDate,
    locale,
    prefix: labelFrom,
  });
  const formattedStartTimeAt = useFormatTime({
    date: startDate,
    locale,
    prefix: labelAt,
    suffix,
  });
  const formattedStartTimeFrom = useFormatTime({
    date: startDate,
    locale,
    prefix: labelFrom,
  });

  const formattedEndDateTo = useFormatDate({
    date: endDate,
    locale,
    prefix: labelTo,
  });
  const formattedEndTimeTo = useFormatTime({
    date: endDate,
    locale,
    prefix: labelTo,
    suffix,
  });
  const formattedEndTimeAt = useFormatTime({
    date: endDate,
    locale,
    prefix: labelAt,
    suffix,
  });

  const makeSubline = () => {
    // 1) single date (start only)
    if (endDate.getTime() === 0) {
      // utc hours == 0 --> assumed as unset in CMS
      if (startDate.getUTCHours() === 0) {
        return formattedStartDateOn;
      }

      return `${formattedStartDateOn} ${formattedStartTimeAt}`;
    }

    // 2) start and end date

    // 2a) date only
    if (startDate.getUTCHours() === 0) {
      return `${formattedStartDateFrom} ${formattedEndDateTo}`;
    }

    // 2b) date on same day
    if (isSameDate(startDate, endDate)) {
      return `${formattedStartDateOn} ${formattedStartTimeFrom} ${formattedEndTimeTo}`;
    }

    // 2c) date on different days
    return `${formattedStartDateFrom} ${formattedStartTimeAt} ${formattedEndDateTo} ${formattedEndTimeAt}`;
  };

  return (
    <div className={styles.wrapper}>
      <InlineSVG
        svg={
          event.flatData.category?.[0]?.flatData.icon?.[0]?.localFile?.svg
            ?.content
        }
        className={styles.icon}
      />
      <div className={styles.content}>
        <Title wrapper="h3" weight="fw-700" className={styles.title}>
          <Trans data={event.data.title} />
        </Title>
        <Text size="fs-2">{makeSubline()}</Text>
      </div>
    </div>
  );
};
