import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { filterActivePages, filterInternalPages, sortPages } from '../../utils';

import { useSettings } from './use-settings';

export const useRootPages = (): readonly Queries.Squidex_Pages[] => {
  const {
    squidex: { queryPagesContents: pages },
  } = useStaticQuery<Queries.Query>(query);
  const settings = useSettings();

  return useMemo(() => {
    const activePages = filterActivePages(pages, settings);
    const internalPages = filterInternalPages(activePages);
    const sortedPages = sortPages(internalPages);

    return sortedPages;
  }, [pages, settings]);
};

const query = graphql`
  query useRootPagesQuery {
    squidex {
      queryPagesContents(
        filter: "data/parent/iv eq null"
        orderby: "data/rank/iv asc"
      ) {
        ...Page
        referencingPagesContents {
          ...Page
        }
      }
    }
  }
`;
